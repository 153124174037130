<template>
  <div class="workspace-tabs" ref="scrollTabs">
    <button @click.stop="scroll('left')" class="scroll-left"><i class="material-icons">chevron_left</i></button>
    <div 
      v-for="tab in tabs" 
      :key="tab.id"
      :id="tab.id"
      class="workspace-tabs__tab" 
      :class="{'active': tab.id == tabActive.id}" :title="$t(`left_menu.${tab.module}.title`)"
      @click="tab.id != tabActive.id ? setActiveTab(tab) : ''">
      <!-- Spot -->
      <strong v-if="tab.name == 'spot/detail'">
        <template v-if="`${tab.id}`.includes('create')">
          {{$t(`left_menu.${tab.module}.spotSingular`)}} - {{$t(`left_menu.${tab.module}.add`)}} 
        </template>
        <template v-else>
          {{$t(`left_menu.${tab.module}.spotSingular`)}} - {{tab.extendedData.internalOrderNumber}}/{{tab.extendedData.internalOrderNumberRevision}} 
        </template>
        <small class="material-icons" v-if="tab.extendedData.changes">cached</small>
      </strong>
      <strong v-else>{{$t(`left_menu.${tab.module}.${tab.name}`)}} <small class="material-icons" v-if="tab.extendedData.changes">cached</small></strong>
      <i class="material-icons" v-if="tabs.length > 1" @click.stop="removeTab(tab)">close</i>
    </div>
    <button @click.stop="scroll('right')" class="scroll-right"><i class="material-icons">navigate_next</i></button>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed, onMounted, onUnmounted, ref, watch } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import eventBus from '@/plugins/eventBus';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const tabs = computed(() => store.getters.tabs_getTabs);
    const tabActive = computed(() => store.getters.tabs_getActive);
    const scrollTabs = ref();

    onMounted(() => {
      eventBus.$on('removeTab', (tab) => {
        removeTab(tab, true);
      });
    })

    onUnmounted(() => {
      eventBus.$off('removeTab');
    });
    
    async function removeTab(tab, force = false) {
      if (tab.extendedData.changes && !force) {
        await store.dispatch('modal_setActive', {active: 'COMMON_CLOSE_TAB', props: tab});
      } else {
        const isActive = tab.id == tabActive.value.id;
        await store.dispatch('tabs_removeTab', tab);
  
        if (isActive) {
          await store.dispatch('tabs_selectFirstTab');
          router.push(`/${tabActive.value.module}/${tabActive.value.name}/${tabActive.value.id}`);
        }
      }
    }

    async function setActiveTab(tab) {
      await store.dispatch('tabs_setActive', tab);
      router.push(`/${tab.module}/${tab.name}/${tab.id}`);
    }

    function scroll(direction) {
      if (direction == 'left') {
        scrollTabs.value.scrollLeft -= 240;
      } else {
        scrollTabs.value.scrollLeft += 240;
      }
    }

    watch(() => tabActive.value, () => {
      const element = document.getElementById(`${tabActive.value.id}`);
      scrollTabs.value.scrollLeft = element.offsetLeft > 0 ? element.offsetLeft - 40 : element.offsetLeft;
    });

    return {
      tabs,
      tabActive,
      scrollTabs,
      removeTab,
      setActiveTab,
      scroll
    }
  }
  
}
</script>
<style lang="scss">
@import '@/styles/_variables.scss';

.workspace-tabs {
  display: flex;
  flex-direction: row;
  height: 40px;
  overflow-x: scroll;
 
  &::-webkit-scrollbar {
    display: none;
  }

  &__tab {
    background: $darker-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    min-width: 240px;
    gap: 8px;
    cursor: pointer;
    opacity: .6;
    text-transform: uppercase;
    // z-index: 1;

    &.active {
      // color: $secondary-color;
      color: #82e9de;
      opacity: 1;
      border-top: 2px solid;
    }

    strong {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      small {
        font-size: 12px;
        margin-left: 4px;
      }
    }

    &:hover i {
      display: block;
    }

    i {
      cursor: pointer;
      display: none;
    }
  }

  &:hover {
    .scroll-left, .scroll-right {
      display: flex;
    }  
  }

  .scroll-left, .scroll-right {
    display: none;
    position: absolute;
    z-index: 1;
    color: white;
    height: 24px;
    width: 24px;
    margin: 4px;
    padding: 0;
    border-radius: 50%;
    background: $lighter-gray-color;
    box-shadow: 0px 0px 12px $light-gray-color;
    justify-content: center;
    align-items: center;
    
    i {
      font-size: 16px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .scroll-left {
    top: 4px;
    left: -16px;
  }

  .scroll-right {
    top: 4px;
    right: -16px;
  }
}
</style>