import interceptor from '@/plugins/interceptor';
const pathController = '/SpotCategory';

export default {
  state: () => ({
    spots: []
  }),
  getters: {
    spotsCategory_spots: state => filter => state.spots.filter(spot => {
      if (filter.name && filter.code) return spot.name.toLowerCase().includes(filter.name.toLowerCase()) && spot.code.toLowerCase().includes(filter.code.toLowerCase())
      if (filter.name) return spot.name.toLowerCase().includes(filter.name.toLowerCase())
      if (filter.code) return spot.code.toLowerCase().includes(filter.code.toLowerCase())
      return true;
    })
  },
  mutations: { 
    spotsCategory_setSpots(state, value) {
      state.spots = value;
    },
    spotsCategory_updateSpots(state, model) {
      const idx = state.spots.findIndex(p => p.id == model.id);
      state.spots[idx] = model;
    },
    spotsCategory_addSpots(state, model) {
      state.spots.push(model);
    },
    spotsCategory_deleteSpots(state, id) {
      const idx = state.spots.findIndex(p => p.id == id);
      state.spots.splice(idx, 1);
    }
  },
  actions: {
    async spotsCategory_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('spotsCategory_setSpots', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.commercial_config.commercial'), error: true});
        throw new Error(error);
      }
    },
    async spotsCategory_create({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        commit('spotsCategory_addSpots', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.commercial_config.commercial')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.commercial'), error: true});
        throw new Error(error);
      }
    },
    async spotsCategory_update({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        commit('spotsCategory_updateSpots', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.commercial_config.commercial')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.commercial'), error: true});
        throw new Error(error);
      }
    }, 
    async spotsCategory_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?spotCategoryId=${model.id}`);
        commit('spotsCategory_deleteSpots', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.commercial_config.commercial')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.commercial'), error: true});
        throw new Error(error);
      }
    },
  }
}

