export default {
  state: () => ({
    active: "",
    props: {}
  }),
  getters: {
    modal_getActive: state => state.active,    
    modal_getProps: state => state.props,
    modal_getActionDelete: state => state.actionDelete   
  },
  mutations: {
    modal_setActive(state, model = {active: '', props: {}, actionDelete: ''}) {
      state.active = model.active;
      state.props = model.props;
      state.actionDelete = model.actionDelete;
    }
  },
  actions: {
    modal_setActive({commit}, modal) {
      commit('modal_setActive', modal);
    }
  }
}