import interceptor from '@/plugins/interceptor';
const pathController = '/AccountExecutive';

export default {
  state: () => ({
    accountExecutive: []
  }),
  getters: {
    accountExecutive_accountExecutive: state => filter => state.accountExecutive.filter(account => {
      if (filter.name && filter.code) return account.name.toLowerCase().includes(filter.name.toLowerCase()) && account.code.toLowerCase().includes(filter.code.toLowerCase())
      if (filter.name) return account.name.toLowerCase().includes(filter.name.toLowerCase())
      if (filter.code) return account.code.toLowerCase().includes(filter.code.toLowerCase())
      return true;
    }),
  },
  mutations: { 
    accountExecutive_setAccountExecutive(state, value) {
      state.accountExecutive = value;
    },
    accountExecutive_updateAccountExecutive(state, model) {
      const idx = state.accountExecutive.findIndex(p => p.id == model.id);
      state.accountExecutive[idx] = model;
    },
    accountExecutive_addAccountExecutive(state, model) {
      state.accountExecutive.push(model);
    },
    accountExecutive_deleteAccountExecutive(state, id) {
      const idx = state.accountExecutive.findIndex(p => p.id == id);
      state.accountExecutive.splice(idx, 1);
    }
  },
  actions: {
    async accountExecutive_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('accountExecutive_setAccountExecutive', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.commercial_config.account_executive'), error: true});
        throw new Error(error);
      }
    },
    async accountExecutive_create({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        commit('accountExecutive_addAccountExecutive', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.commercial_config.account_executive')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.account_executive'), error: true});
        throw new Error(error);
      }
    },
    async accountExecutive_update({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        commit('accountExecutive_updateAccountExecutive', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.commercial_config.account_executive')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.account_executive'), error: true});
        throw new Error(error);
      }
    }, 
    async accountExecutive_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?accountExecutiveId=${model.id}`);
        commit('accountExecutive_deleteAccountExecutive', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.commercial_config.account_executive')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.account_executive'), error: true});
        throw new Error(error);
      }
    },
  }
}

