import interceptor from '@/plugins/interceptor';
const pathController = '/Client'

export default {
  state: () => ({
    clients: []
  }),
  getters: {
    client_clients: state => filter => state.clients.filter(client => {
      // Obtener los que tienen valor
      const filtersActives = {
        code: (filter.code != ''),
        name: (filter.name != ''),
        taxCode: (filter.taxCode != '')
      };
      // Consultar solo por los activos
      let valid = true;
      Object.keys(filtersActives).forEach(active => {
        if (filtersActives[active] && valid) {
          valid = `${client[active]}`.toLowerCase().includes(`${filter[active]}`.toLowerCase());
        }
      });

      return valid;
    }),
  },
  mutations: { 
    client_setClients(state, value) {
      state.clients = value;
    },
    client_updateClients(state, model) {
      const idx = state.clients.findIndex(p => p.id == model.id);
      state.clients[idx] = model;
    },
    client_addClients(state, model) {
      state.clients.push(model);
    },
    client_deleteClients(state, id) {
      const idx = state.clients.findIndex(p => p.id == id);
      state.clients.splice(idx, 1);
    }
  },
  actions: {
    async client_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('client_setClients', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.spot_programming.client'), error: true});
        throw new Error(error);
      }
    },
    async client_create({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        commit('client_addClients', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.spot_programming.client')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.spot_programming.client'), error: true});
        throw new Error(error);
      }
    },
    async client_update({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        commit('client_updateClients', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.spot_programming.client')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.spot_programming.client'), error: true});
        throw new Error(error);
      }
    }, 
    async client_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?clientId=${model.id}`);
        commit('client_deleteClients', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.spot_programming.client')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.spot_programming.client'), error: true});
        throw new Error(error);
      }
    },
  }
}

