import { createStore } from 'vuex'
import Tabs from '@/store/Tabs';
import Modal from '@/store/Modal';
import Notifications from '@/store/Notifications';
import Loading from '@/store/Loading';
//COMMON
import ManageUser from '@/store/common/ManageUser';
//API
import Authentication from '@/store/API/authentication';
import Common from '@/store/API/common';
// - CommercialConfig
import AccountExecutive from '@/store/API/CommercialConfig/accountExecutive';
import ClientCategory from '@/store/API/CommercialConfig/clientCategory';
import AdAgency from '@/store/API/CommercialConfig/adAgency';
import AdAgencyGroup from '@/store/API/CommercialConfig/adAgencyGroup';
import PayMethod from '@/store/API/CommercialConfig/payMethod';
import Origin from '@/store/API/CommercialConfig/origin';
import BusinessUnit from '@/store/API/CommercialConfig/businessUnit';
import SpotCategory from '@/store/API/CommercialConfig/spotCategory';
// - StationConfig
import Station from '@/store/API/StationConfig/station';
import Speaker from '@/store/API/StationConfig/speaker';
import Show from '@/store/API/StationConfig/show';
import Schedule from '@/store/API/StationConfig/schedule';
// - SpotProgramming
import Client from '@/store/API/SpotProgramming/client';
import Spot from '@/store/API/SpotProgramming/spot';
import TrafficLog from '@/store/API/SpotProgramming/trafficLog';

export default createStore({
  modules: {
    Tabs,
    Modal,
    Notifications,
    Loading,
    //COMMON
    ManageUser,
    Common,
    // API
    Authentication,
    AccountExecutive,
    ClientCategory,
    AdAgency,
    AdAgencyGroup,
    PayMethod,
    Origin,
    BusinessUnit,
    Station,
    Speaker,
    Show,
    Schedule,
    Client,
    SpotCategory,
    Spot,
    TrafficLog
  }
})
