<template>
  <div class="component-modal" id="modal">
    <div class="form" v-on:submit.prevent>
      <legend>{{ $t("modal.common.delete.title") }}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>

      <h3 class="component-modal__question">{{ $t("modal.common.delete.question") }}</h3>
      <div class="component-modal__item-detail">
        <ul>
          <template v-for="key in Object.keys(props)" :key="key">
            <li v-if="key != 'id' && key != 'internalReferenceSpot'">
              <strong>{{ $t(`modal.common.delete.props.${key}`) }}</strong> : {{props[key] || '-'}}
            </li>  
          </template>
        </ul> 
      </div>
      
      <button type="button" @click.stop.prevent="saveAndClose">
        {{ $t('modal.confirm') }}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{ $t("modal.cancel") }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import eventBus from '@/plugins/eventBus';

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const props = computed(() => store.getters.modal_getProps);
    const actionDelete = computed(() => store.getters.modal_getActionDelete);

    async function closeModal() {
      await store.dispatch('modal_setActive');
    }

    async function saveAndClose() {
      if (actionDelete.value.includes("EMIT_")) {
        eventBus.$emit(`${actionDelete.value.split('EMIT_')[1]}`, props.value);
        this.closeModal();
      } else {
        try {
          if (actionDelete.value == 'schedule_removeCommercialBreaks' || actionDelete.value == 'schedule_removeShows') {
            await store.dispatch(actionDelete.value, {tabId: route.params.id, ...props.value});
            await store.dispatch('tabs_setPropChanges', {id: route.params.id, changes: true});
          } else {
            await store.dispatch(actionDelete.value, props.value);
          }
          this.closeModal();
        } catch (error) {
          console.log(error);
        }
      }
    }

    return {
      props,
      saveAndClose,
      closeModal
    }
  }
};
</script>
<style lang="scss">
  h3 {
    text-align: center;
  }
  .item-detail {
    background: 
    ul {
      text-decoration: none;
    }
  }
</style>
