<template>
  <div class="workspace">
    <TopNavbar />
    <LeftMenu />
    <div class="workspace__container">
      <WorkspaceTabs /> 
      <RouterView />
    </div>
    <ContainerModal />
    <Loading />
  </div>
</template>

<script>
import TopNavbar from '@/components/Common/TopNavbar';
import LeftMenu from '@/components/Common/LeftMenu';
import WorkspaceTabs from '@/components/Workspace/Tabs';
import ContainerModal from '@/components/Modal/Container';
import Loading from '@/components/Common/Loading';

export default {
  components: {
    TopNavbar,
    LeftMenu,
    WorkspaceTabs,
    ContainerModal,
    Loading
  }
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.workspace {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-template-rows: 64px 1fr;
  gap: 0px 0px;
  grid-template-areas:
      "left-menu top-bar"
      "left-menu central";
	background: url('~@/assets/images/Elipse60.svg') 120% -200px no-repeat, linear-gradient(180deg, #3c3280, #191538);
  background-blend-mode: multiply;

  &__container {
    height: calc(100vh - 80px);
    position: relative;
    grid-area: central;
    display: grid;
    grid-template-rows: 40px 1fr;
    // gap: 16px;
    margin: 0 16px;

    &__view {
      background: $darker-color;
      border-radius: 0 0 8px 8px;
    }
  }
}

.workspace__container__view {
  width: 100%;
  padding: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    &.column {
      flex-direction: column;

      .settings-input {
        min-width: 250px;
        width: 100%;

        &.double-column {
          // 12px - gap
          min-width: 512px;

          textarea {
            height: 64px;
          }
        }
      }
    }
    
    &__filter {
      display: flex;
      flex-direction: row;
      gap: 12px;
  
      .container__inputs {
        display: flex;
        gap: 12px;
      }
  
      .settings-input {
        max-width: 600px;
        width: 50%;
        // margin-bottom: 16px;
        display: inline-block;

        &.full-size {
          width: 100%;
        }
  
        // small {
        //   font-style: italic;
        //   color: $ultra-lighter-gray-color;
        // }
  
        input, textarea {
          background-color: transparent;
          color: rgba(255,255,255,.87);
          border: none;
          border-bottom: 1px solid rgba(255,255,255,1);
          outline: 0;
          width: 100%;
          padding: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
          border-radius: 0;
          font-size: 14px;
          font-family: inherit;
          line-height: 1;
          background-image: none;
          height: 32px;
          padding-top: 0;
  
          &:focus {
            border-color: $secondary-color;
          }
  
          &:disabled {
            opacity: .5;
          }
        }
  
        // input[type=file] {
        //   border-bottom: none;
        //   padding-top: $padding-8;
        // }
  
        // input[type=date] {
        //   max-width: 160px;
  
        //   &::-webkit-clear-button {
        //     display: none;
        //   }
  
        //   &::-webkit-inner-spin-button { 
        //     display: none;
        //   }
  
        //   &::-webkit-calendar-picker-indicator {
        //     filter: invert(1);
        //   }
        // }
          
        // input[type=range] {
        //   margin: 16px 0 0 0;
        //   padding-top: 0px;
        // }
  
        // input[type=range] {
        //   -webkit-appearance: none;
        //   border: none;
        // }
  
        // input[type=range]::-webkit-slider-runnable-track {
        //   height: 1px;
        //   background-color: #ff5252!important;
        //   border: none;
        // }
  
        // input[type=range]::-webkit-slider-thumb {
        //   -webkit-appearance: none;
        //   border: none;
        //   height: 16px;
        //   width: 16px;
        //   border-radius: 50%;
        //   background: #ff5252;
        //   margin-top: -8px;
        // }
  
        // input[type=range]:focus {
        //   outline: none;
        // }
  
        // /* thumb active */
        // input[type="range"]::-webkit-slider-thumb:active {
        //   transform:scale(1.1);
        // }
        // input[type="range"]::-moz-range-thumb:active{
        //   transform:scale(1.1);
        // }
  
        select {
          background-color: transparent;
          color: rgba(255,255,255,.87);
          border: none;
          border-bottom: 1px solid rgba(255,255,255,1);
          outline: 0;
          width: 100%;
          padding: 0 24px 0 0;
          -webkit-box-shadow: none;
          box-shadow: none;
          border-radius: 0;
          font-size: 14px;
          font-family: inherit;
          height: 32px;
          padding-top: 0;
          text-transform: capitalize;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 8px;
          cursor: pointer;
  
          option {
            color: black;
          }
        }
  
        label {
          display: block;
          width: 100%;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
  
        // textarea {
        //   background-color: transparent;
        //   color: rgba(255,255,255,.87);
        //   border: none;
        //   border-bottom: 1px solid rgba(255,255,255,1);
        //   outline: 0;
        //   width: 100%;
        //   padding: 0;
        //   -webkit-box-shadow: none;
        //   box-shadow: none;
        //   border-radius: 0;
        //   font-size: 14px;
        //   font-family: inherit;
        //   line-height: 1;
        //   background-image: none;
        //   padding-top: 0;
        //   width: 100%!important;
        //   height: 56px;
        //   margin-top: 8px;
  
        //   &:focus {
        //     border-color: $secondary-color;
        //   }
  
        //   &:disabled {
        //     opacity: .5;
        //   }
        // }
  
        h4 {
          margin: 0;
          font-size: $font-size-medium;
        }
      }
  
      button {
        font-family: $font-roboto-cond, sans-serif;
        font-size: 14px;
        color: $light-gray-color;
        background: $primary-color;
        padding: 8px 32px;
        text-align: center;
        white-space: nowrap;
        // float: right;
        text-transform: uppercase;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    
        &:hover, &:disabled {
          opacity: .5;
        }
      }
    }
  }

  &__container--two {
    display: flex;
    gap: 24px;
    justify-content: space-between;

    & > div {
      width: 50%;
    }
  }

  &__table {
    width: 100%;

    &__header {
      display: flex;
      margin-bottom: 12px;
      gap: 12px;

      h3 {
        margin: 4px;
      }

      .container__inputs {
        display: flex;
        gap: 12px;
      }
  
      .settings-input {
        max-width: 600px;
        display: inline-block;

        input{
          background-color: transparent;
          color: rgba(255,255,255,.87);
          border: none;
          border-bottom: 1px solid rgba(255,255,255,1);
          outline: 0;
          width: 100%;
          padding: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
          border-radius: 0;
          font-size: 14px;
          font-family: inherit;
          line-height: 1;
          background-image: none;
          height: 32px;
          padding-top: 0;
  
          &:focus {
            border-color: $secondary-color;
          }
  
          &:disabled {
            opacity: .5;
          }
        }
      }
    }
    
    .actions {
      cursor: pointer;
      text-align: center;
      width: 120px;
    }

    thead {
      background-color: $darker-gray-color;
      text-transform: uppercase;
      height: 40px;
      text-align: left;
    }

    tbody tr td {
      border-bottom: 1px solid $darker-gray-color;
      height: 32px;
    }

    td.checkbox {
      display: flex;
      align-items: center;
      gap: 4px;

      input[type="checkbox"] {
        appearance: none;
        width: 20px;
        height: 20px;
        background: none;
        border: 1px solid $primary-color;
        border-radius: 2px;
        position: relative;
        flex-shrink: 0;

        &:active, &:checked:active {
          background-color: $primary-color;
        }

        &:checked {
          background-color: $primary-color;
        }
      }
    }
  }

  &__actions {
    display: flex;
    gap: 12px;
    position: absolute;
    bottom: 0;
    right: 8px;
    button {
      margin: 0px 4px;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 14px;
    // border: 2px solid $dark-gray-color;
    // border-radius: 12px;
    padding: 32px;

    i {
      font-size: 40px;
    }
  }
}
</style>