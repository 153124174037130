import interceptor from '@/plugins/interceptor';
const pathController = '/BusinessUnit'

export default {
  state: () => ({
    businessUnit: []
  }),
  getters: {
    businessUnit_businessUnit: state => filter => state.businessUnit.filter(unit => {
      if (filter.code && filter.description) return unit.code.toLowerCase().includes(filter.code.toLowerCase()) && unit.description.toLowerCase().includes(filter.description.toLowerCase())
      if (filter.description) return unit.description.toLowerCase().includes(filter.description.toLowerCase())
      if (filter.code) return unit.code.toLowerCase().includes(filter.code.toLowerCase())
      return true;
    }),
  },
  mutations: { 
    businessUnit_setBusinessUnit(state, value) {
      state.businessUnit = value;
    },
    businessUnit_updateBusinessUnit(state, model) {
      const idx = state.businessUnit.findIndex(p => p.id == model.id);
      state.businessUnit[idx] = model;
    },
    businessUnit_addBusinessUnit(state, model) {
      state.businessUnit.push(model);
    },
    businessUnit_deleteBusinessUnit(state, id) {
      const idx = state.businessUnit.findIndex(p => p.id == id);
      state.businessUnit.splice(idx, 1);
    }
  },
  actions: {
    async businessUnit_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('businessUnit_setBusinessUnit', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.commercial_config.business_unit'), error: true});
        throw new Error(error);
      }
    },
    async businessUnit_create({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        commit('businessUnit_addBusinessUnit', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.commercial_config.business_unit')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.business_unit'), error: true});
        throw new Error(error);
      }
    },
    async businessUnit_update({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        commit('businessUnit_updateBusinessUnit', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.commercial_config.business_unit')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.business_unit'), error: true});
        throw new Error(error);
      }
    }, 
    async businessUnit_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?businessUnitId=${model.id}`);
        commit('businessUnit_deleteBusinessUnit', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.commercial_config.business_unit')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.business_unit'), error: true});
        throw new Error(error);
      }
    },
  }
}

