import persistentData from "@/plugins/persistentData";
import moment from "moment";

let intervalRefreshToken = null;

export default {
  state: () => ({
    logged: false,
    user : {},
    multimedia: null
  }),
  getters: {
    manageUser_logged: state => state.logged,
    manageUser_user: state => state.user,
    manageUser_multimedia : state => state.multimedia
  },
  mutations: { 
    manageUser_setLogged(state, value) {
      state.logged = value;
    },
    manageUser_setUser(state, value) {
      state.user = value;
    },
    manageUser_setMultimedia(state, multimedia) {
      state.multimedia = multimedia;
    },
  },
  actions: {
    manageUser_active({ commit, dispatch }) {
      if (persistentData.get('token')) {
        // Validate tokenExpiration
        const validateToken = async function() {
          const expiredToken = moment(persistentData.get('tokenExpiration')).diff(moment());
          if (expiredToken < 0) {
            try {
              const request = await dispatch('authentication_refreshToken', {value: persistentData.get('token')});
              if (request.status === 200) {
                persistentData.set('token', request.data.token);
                persistentData.set('tokenExpiration', request.data.tokenExpiration);
              } else {
                dispatch('manageUser_disabled');
              }
            } catch (error) {
              dispatch('manageUser_disabled');
            }
          }
        }

        validateToken();
        if (intervalRefreshToken) clearInterval(intervalRefreshToken)
        
        intervalRefreshToken = setInterval(() => {
          validateToken();
        }, 10000);
        
        commit('manageUser_setLogged', true);
        commit('manageUser_setUser', JSON.parse(persistentData.get('user')));
        
        // Redirect cuando esta logueado
        setTimeout(() => {
          const expiredToken = moment(persistentData.get('tokenExpiration')).diff(moment());
          if (expiredToken > 0 && window.vueApp.$route.path == '/login') {
            window.vueApp.$router.push('/');   
          }
        })
      }
    },
    manageUser_disabled({ commit }) {
      persistentData.remove('token');
      persistentData.remove('tokenExpiration');
      persistentData.remove('user');
      // persistentData.remove('showId');
      // persistentData.remove('stationId');
      if (intervalRefreshToken) clearInterval(intervalRefreshToken);
      commit('manageUser_setLogged', false);
    },
    manageUser_setMultimedia({ commit }, multimedia) {
      commit('manageUser_setMultimedia', multimedia);
    }
  }
}