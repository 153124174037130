<template>
  <div class="component-modal" id="modal">
    <form class="form" v-on:submit.prevent>
      <legend>{{ $t("modal.station_config.schedules.copy_day") }}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>
      
      <div class="component-modal__item-detail">
        <ul>
          <template v-for="key in Object.keys(modelProps)" :key="key">
            <li v-if="key != 'id' && key != 'stationId'">
              <strong>{{ $t(`modal.common.delete.props.${key}`) }}</strong> : {{key == 'dayOfWeek' ? getDayOfWeek(props[key]) : props[key]}}
            </li>  
          </template>
        </ul> 
      </div>

      <div class="component-modal__row--half">
        <div class="component-modal__column--half">
          <label>{{ $t("modal.station_config.schedules.days_to_copy") }} <small>*</small></label>
          <div class="component-modal-checkbox" :class="{'disabled': modelProps.dayOfWeek == 0}">
            <label>
              <input type="checkbox" v-model="daysToCopy.sunday" :disabled="modelProps.dayOfWeek == 0">
              {{ $t("days_of_week.sunday") }}  
            </label>
          </div>
          <div class="component-modal-checkbox" :class="{'disabled': modelProps.dayOfWeek == 1}">
            <label>
              <input type="checkbox" v-model="daysToCopy.monday" :disabled="modelProps.dayOfWeek == 1">
              {{ $t("days_of_week.monday") }}
            </label>
          </div>
          <div class="component-modal-checkbox" :class="{'disabled': modelProps.dayOfWeek == 2}">
            <label>
              <input type="checkbox" v-model="daysToCopy.tuesday" :disabled="modelProps.dayOfWeek == 2">
              {{ $t("days_of_week.tuesday") }}
            </label>
          </div>
          <div class="component-modal-checkbox" :class="{'disabled': modelProps.dayOfWeek == 3}">
            <label>
              <input type="checkbox" v-model="daysToCopy.wednesday" :disabled="modelProps.dayOfWeek == 3">
              {{ $t("days_of_week.wednesday") }}
            </label>
          </div>
          <div class="component-modal-checkbox" :class="{'disabled': modelProps.dayOfWeek == 4}">
            <label>
              <input type="checkbox" v-model="daysToCopy.thursday" :disabled="modelProps.dayOfWeek == 4">
              {{ $t("days_of_week.thursday") }}
            </label>
          </div>
          <div class="component-modal-checkbox" :class="{'disabled': modelProps.dayOfWeek == 5}">
            <label>
              <input type="checkbox" v-model="daysToCopy.friday" :disabled="modelProps.dayOfWeek == 5">
              {{ $t("days_of_week.friday") }}
            </label>
          </div>
          <div class="component-modal-checkbox" :class="{'disabled': modelProps.dayOfWeek == 6}">
            <label>
              <input type="checkbox" v-model="daysToCopy.saturday" :disabled="modelProps.dayOfWeek == 6">
              {{ $t("days_of_week.saturday") }}
            </label>
          </div>
        </div>

        <div class="component-modal__column--half">
          <label>{{ $t("modal.station_config.schedules.items_to_copy") }} <small>*</small></label>
          <div class="component-modal-checkbox">
            <label>
              <input type="checkbox" v-model="copyCommercialBreaks">
              {{ $t("modal.station_config.schedules.schedules") }}  
            </label>
          </div>
          <div class="component-modal-checkbox">
            <label for="allow">
              <input type="checkbox" v-model="copyShows">
              {{ $t("modal.station_config.schedules.program") }}  
            </label>
          </div>
        </div>
      </div>

      <!-- Alert validation -->
      <div class="alert warning" v-if="invalidForm">
        <i class="material-icons">info_outline</i>
        {{$t("modal.station_config.schedules.checkFields")}}
      </div>
    
      <button type="button" @click.stop.prevent="saveAndClose">
        {{ props ? $t("modal.save") : $t('modal.create') }}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{ $t("modal.cancel") }}
      </button>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const store = useStore();
    const props = computed(() => store.getters.modal_getProps);
    const modelProps = ref({});
    const invalidForm = ref(false);
    const { t } = useI18n();
    const daysToCopy = ref({
      sunday: false,
      monday: false,       
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false
    })
    const copyCommercialBreaks = ref(false);
    const copyShows = ref(false);

    onMounted(() => {
      if (props.value) modelProps.value = Object.assign({}, props.value);
    })

    async function closeModal() {
      await store.dispatch('modal_setActive');
      invalidForm.value = false;
    }

    async function saveAndClose() {
      let model = {
        stationId: modelProps.value.stationId,
        dayOfWeekFrom: modelProps.value.dayOfWeek,
        dayOfWeekTo: [],
        copyCommercialBreaks: copyCommercialBreaks.value,
        copyShows: copyShows.value
      };
      // Generate array days
      Object.keys(daysToCopy.value).forEach((day, idx) => {
        if (daysToCopy.value[day]) model.dayOfWeekTo.push(idx);
      });

      if (model.dayOfWeekTo.length > 0 && (copyCommercialBreaks.value || copyShows.value)) {        
        try {
          await store.dispatch('schedule_copyDay', model);
          this.closeModal();
        } catch (error) {
          console.log(error);
        }
      } else {
        invalidForm.value = true;
      }
    }

    function getDayOfWeek(day) {
      let label = '';
      switch(day) {
        case 0:
          label = t("days_of_week.sunday") 
          break;
        case 1:
          label = t("days_of_week.monday") 
          break;
        case 2:
          label = t("days_of_week.tuesday") 
          break;
        case 3:
          label = t("days_of_week.wednesday") 
          break;
        case 4:
          label = t("days_of_week.thursday") 
          break;
        case 5:
          label = t("days_of_week.friday") 
          break;
        case 6:
          label = t("days_of_week.sunday") 
          break;
      }

      return label;
    }

    return {
      props,
      modelProps,
      daysToCopy,
      copyCommercialBreaks,
      copyShows,
      invalidForm,
      saveAndClose,
      closeModal,
      getDayOfWeek
    }
  }
};
</script>