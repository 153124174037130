<template>
  <div class="login">
		<div class="login__logo">SPOTM<i class="material-icons">cell_tower</i>STER</div>
    <!-- <img src="@/assets/images/insoft-logo.svg" alt="Admicom Logo"> -->
    <div class="login__modal">
      <h1 class="big-title"><span>{{$t('login.title')}}</span></h1>
      <form class="form">
        <input type="text" :placeholder="$t('login.user')" v-model="modelLogin.email" @keyup.enter="login" v-focus :class="{'invalid': submitted && v$.modelLogin.email.$invalid}">
        <input type="password" :placeholder="$t('login.password')" v-model="modelLogin.password" @keyup.enter="login" :class="{'invalid': submitted && v$.modelLogin.password.$invalid}">
        <button type="button" @click.stop.prevent="login" :disabled="processReq">{{ processReq ? $t("login.process") : $t('login.login') }}</button>
      </form>
    </div>
    <div class="login__footer">
      <div class="login__footer__language">
        <i class="material-icons">language</i>
        <select v-model="langSelect" @change="changeLanguage">
          <option value="EN">{{$t("settings.change_language.en")}}</option>
          <option value="ES">{{$t("settings.change_language.es")}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import persistentData from '@/plugins/persistentData';

export default {
	setup() {
		const { t } = useI18n();
		const v$ = useVuelidate();
		const store = useStore();
		const router = useRouter();
		const submitted = ref(false);
		const processReq = ref(false);
		const langSelect = ref('');
		const modelLogin = ref({
			email: '',
			password: ''
		});

		onMounted(() => {
			langSelect.value = persistentData.get('lang') || 'EN';
		});

		async function login() {
			submitted.value = true;
			if (!v$.value.modelLogin.$invalid) {
				processReq.value = true;
				try {
					const request = await store.dispatch('authentication_authenticate', modelLogin.value);
					persistentData.set('token', request.data.token);
					persistentData.set('tokenExpiration', request.data.tokenExpiration);
					persistentData.set('user', JSON.stringify(request.data));
					store.dispatch('manageUser_active');
					router.push('/');
				} catch (e) {
					store.dispatch('notifications_create', { text: t(`notifications.general`) + e.message, title: t(`login.login`), error: true });
				} finally {
					submitted.value = false;
					processReq.value = false;
				}
			}
		}

		function changeLanguage() {
			persistentData.set('lang', langSelect.value);
			window.i18n.global.locale.value = langSelect.value;
		}

		return {
			modelLogin,
			login,
			langSelect,
			processReq,
			changeLanguage,
			submitted,
			v$
		}
	},
	validations () {
    return {
      modelLogin: {
				email: {required},
				password: {required}
			}
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.login {
	width: 100vw;
	height: 100vh;
	background: url('~@/assets/images/Elipse60.svg') 120% -200px no-repeat, linear-gradient(180deg, #7763ff,#3c3280);
	background-blend-mode: multiply;
	display: flex;
	justify-content: center;
	align-items: center;

	&__logo {
		position: absolute;
		width: 140px;
    top: 16px;
    left: 16px;
		margin: 0;
		font-size: 28px;
		display: flex;
		align-items: center;
		font-family: "Roboto Condensed", sans-serif;

    i {
      margin-top: -14px;
      font-size: 40px;
    }
	}

	.big-title {
		color: $primary-color;
		font-size: 48px;
		margin: 0 auto 24px;
		position: relative;
		width: fit-content;

		span {
			position: relative;
			top: 0;
			padding-right: 8px;
		}

		&:before {
			content: '';
			height: 40px;
			width: 100%;
			display: block;
			position: absolute;
			left: 0;
			top: - 4px;
			background: #e98286;
		}
	}

	&__modal {
		max-width: 480px;
		width: 100%;
		padding: 0 24px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;

		.form {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
		}
	
		img {
			width: 180px;
			display: block;
		}
	
		input {
			background-color: transparent;
			color: $primary-color;
			border: 1px solid $primary-color;
			border-radius: 8px;
			outline: 0;
			width: 100%;
			padding: 0;
			-webkit-box-shadow: none;
			box-shadow: none;
			font-size: 14px;
			font-family: inherit;
			line-height: 1;
			background-image: none;
			height: 48px;
			padding: 0 16px;
			margin-top: 24px;
	
			&:focus {
				border-color: $secondary-color;
			}
	
			&:disabled {
				opacity: .5;
			}

			&::placeholder {
				color: $primary-color;
			}
		}

		button {
			font-family: $font-roboto-cond;
			height: 48px;
			font-size: 16px;
			line-height: 20px;
			text-transform: uppercase;
			border-radius: 24px;
			background: #82e9de;
			color: $darker-color;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border: none;
			margin-bottom: 16px;
			margin-top: 48px;
			max-width: 300px;
			width: 100%;
			cursor: pointer;
			outline: 0;
	
			&:hover, &:disabled {
				opacity: .5;
			}
		}
	}

	&__footer {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 48px;
    background: black;
    width: 100%;
		
		&__language {
			display: flex;
			align-items: center;
			position: absolute;
			color: white;
			right: 16px;
			bottom: 12px;

			i {
				position: absolute;
				left: 12px;
			}

			select {
				background-color: transparent;
				border: none;
				outline: 0;
				width: 100%;
				padding: 0;
				color: white;
				-webkit-box-shadow: none;
				position: relative;
				box-shadow: none;
				font-size: 14px;
				font-family: inherit;
				line-height: 1;
				background-image: none;
				height: 24px;
				padding: 0 16px 0 40px;
				margin-left: 8px;

				option {
					color: black;
				}

				&::placeholder {
					color: white;
				}
			}
		}
  }
}

input.invalid {
  border: 1px solid tomato !important;
}
</style>