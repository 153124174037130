<template>
  <div class="login">
		<h1>SPOTM<i class="material-icons">cell_tower</i>STER</h1>
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import persistentData from '@/plugins/persistentData';

export default {
  setup() {
		const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    onMounted(async () => {
      try {
        const tokenParam = route.query.t;
        const request = await store.dispatch('authentication_insoftAuthenticate', tokenParam);
        persistentData.set('token', request.data.token);
        persistentData.set('tokenExpiration', request.data.tokenExpiration);
        persistentData.set('user', JSON.stringify(request.data));
        store.dispatch('manageUser_active');
        router.push('/');
      } catch (e) {
        store.dispatch('notifications_create', { text: t(`notifications.general`) + e.message, title: t(`login.login`), error: true });
      }
		});
  }
}
</script>
<style lang="scss" scoped>
  @import '@/styles/_variables.scss';

.login {
	width: 100vw;
	height: 100vh;
	background: url('~@/assets/images/Elipse60.svg') 120% -200px no-repeat, linear-gradient(180deg, #7763ff,#3c3280);
	background-blend-mode: multiply;
	display: flex;
  flex-direction: column;
	justify-content: center;
	align-items: center;

	h1 {
		margin: 12px;
		font-size: 48px;
		display: flex;
		align-items: center;
		font-family: "Roboto Condensed", sans-serif;

    i {
      margin-top: -15px;
      font-size: 60px;
    }
	}
}


.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

</style>