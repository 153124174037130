<template>
  <div class="component-modal" id="modal">
    <form class="form" v-on:submit.prevent>
      <legend>{{$t("modal.spot_programming.spot.generate_block.title")}}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>
      
      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <select name="program" id="program" v-model="model.stationId" :class="{'invalid': submitted && v$.model.stationId.$invalid}" >
            <option value="" selected>{{$t("modal.placeholder_select")}} {{$t("modal.spot_programming.spot.generate_block.station")}}</option>
            <option v-for="station in listStations" :value="station.id" :key="station.id">{{station.name}}</option>
          </select>
          <label for="program">{{$t("modal.spot_programming.spot.generate_block.station")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="date" v-model="model.dateFrom" @change="validate()" :class="{'invalid': submitted && (v$.model.dateFrom.$invalid || errorTime)}">
          <label for="start">{{$t("modal.spot_programming.spot.generate_block.date_from")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="date" v-model="model.dateTo" :min="model.dateFrom" @change="validate()" :class="{'invalid': submitted && (v$.model.dateTo.$invalid || errorTime)}">
          <label for="end">{{$t("modal.spot_programming.spot.generate_block.date_to")}} <small>*</small></label>
        </div>
      </div>

      <!-- Alert validation -->
      <div class="alert warning" v-if="errorTime">
        <i class="material-icons">info_outline</i>
        {{$t("modal.station_config.schedules.errorTime")}}
      </div>
    
      <button type="button" @click.stop.prevent="saveAndClose">
        {{$t("modal.save")}}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{$t("modal.cancel")}}
      </button>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { required } from '@vuelidate/validators';
import { useStore } from 'vuex';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';

export default {
  setup() {
		const v$ = useVuelidate();
    const store = useStore();
    const listStations = computed(() => store.getters.station_stations({name: ''}));
    const errorTime = ref(false);
    const submitted = ref(false);
    const model = ref({
      stationId: '',
      dateFrom: moment().format("YYYY-MM-DD"),
      dateTo: ''
    })

    onMounted(() => {
      if (listStations.value.length == 1) model.value.stationId = listStations.value[0].id;
    })

    async function closeModal() {
      await store.dispatch('modal_setActive');
      submitted.value = false;
    }

    async function saveAndClose() {
      submitted.value = true;
      if (!v$.value.model.$invalid && !errorTime.value) {
        try {
          await store.dispatch('trafficLog_generate', model.value);
          this.closeModal();
        } catch (error) {
          console.log(error);
        }
      }
    }

    function validate() {    
      if (model.value.dateFrom != '' && model.value.dateTo != '') {
        // Validar que from sea distinto a to
        if (model.value.dateFrom == model.value.dateTo) {
          errorTime.value = true;
          return;
        }
        // Validar que start sea previo a end
        errorTime.value = moment(model.value.dateFrom).isAfter(model.value.dateTo);
      }
    }

    return {
      v$,
      listStations,
      model,
      submitted,
      errorTime,
      saveAndClose,
      closeModal,
      validate,
    }
  },
	validations () {
    return {
      model: {
        stationId: {required},
        dateFrom: {required},
        dateTo: {required}
			}
    }
  }
};
</script>