<template>
  <div class="customer-facing">
    <header>
      <div class="header__logo">SPOTM<i class="material-icons">cell_tower</i>STER</div>
    </header>
    <div class="customer-facing__container" v-if="!success">
      <div class="title">
        <span>{{ $t("customer_facing.title") }}</span>
      </div>
  
      <div class="input">
        <label for="fullname">{{ $t("customer_facing.full_name") }}</label>
        <input type="text" :placeholder="`${$t('customer_facing.insert')} ${$t('customer_facing.full_name')}`" id="fullname">
      </div>
  
      <div class="input">
        <label for="email">{{ $t("customer_facing.email") }}</label>
        <input type="email" :placeholder="`${$t('customer_facing.insert')} ${$t('customer_facing.email')}`" id="email">
      </div>
      
      <div class="subtitle">
        <span>{{ $t("customer_facing.campaign") }}</span>
      </div>
  
      <div class="input">
        <label for="dateFrom">{{ $t("customer_facing.date_init") }}</label>
        <input type="date" v-model="model.dateFrom">
      </div>
  
      <div class="input">
        <label for="dateTo">{{ $t("customer_facing.date_end") }}</label>
        <input type="date" v-model="model.dateTo" :min="model.dateFrom">
      </div>
  
      <div class="input">
        <label for="program">{{ $t("customer_facing.program") }}</label>
        <select name="program" id="program" v-model="model.program">
          <option :value="11">{{ $t("customer_facing.options.morning") }} (USD 11.00/Spot)</option>
          <option :value="10">{{ $t("customer_facing.options.half_day") }} (USD 10.00/Spot)</option>
          <option :value="9">{{ $t("customer_facing.options.afternoon") }} (USD 9.00/Spot)</option>
          <option :value="8">{{ $t("customer_facing.options.hits") }} (USD 8.00/Spot)</option>
          <option :value="7">{{ $t("customer_facing.options.night") }} (USD 7.00/Spot)</option>
        </select>
      </div>
  
      <div class="input">
        <label for="outputs">{{ $t("customer_facing.daily_output") }}</label>
        <select name="outputs" id="outputs" v-model="model.outputs">
          <option :value="1">1 {{ $t("customer_facing.output") }}</option>
          <option :value="2">2 {{ $t("customer_facing.output") }}s</option>
          <option :value="3">3 {{ $t("customer_facing.output") }}s</option>
          <option :value="4">4 {{ $t("customer_facing.output") }}s</option>
          <option :value="5">5 {{ $t("customer_facing.output") }}s</option>
          <option :value="6">6 {{ $t("customer_facing.output") }}s</option>
          <option :value="7">7 {{ $t("customer_facing.output") }}s</option>
          <option :value="8">8 {{ $t("customer_facing.output") }}s</option>
        </select>
      </div>
      
      <div class="input">
        <label for="outputs">{{ $t("customer_facing.spot") }}</label>
        <label class="file">
          <i class="material-icons">{{file ? 'description' : 'file_upload'}}</i>
          {{file ? $t('customer_facing.selected_file') : $t('customer_facing.select_file')}}
          <input type="file" hidden @change="uploadFile">
        </label> 
      </div>
  
      <h2>{{ $t("customer_facing.total_amount") }} : ${{total}}</h2>
  
      <div class="subtitle">
        <span>{{ $t("customer_facing.pay") }}</span>
      </div>
      <div class="input">
        <label for="fullName">{{ $t("customer_facing.full_name") }}</label>
        <input type="text" :placeholder="$t('customer_facing.full_name')">
      </div>
  
      <div class="input">
        <label for="fullName">{{ $t("customer_facing.card_number") }}</label>
        <input type="text" placeholder="XXXX XXXX XXXX XXXX" v-mask="'#### #### #### ####'">
      </div>
  
      <div class="input">
        <label for="fullName">{{ $t("customer_facing.date_expiration") }}</label>
        <input type="month">
      </div>
  
      <div class="input">
        <label for="fullName">{{ $t("customer_facing.CVC") }}</label>
        <input type="password" placeholder="***" v-mask="'###'">
      </div>
  
      <div class="checkbox">
        <label>
          <input type="checkbox">
          {{ $t("customer_facing.legals") }}
        </label>
      </div>
      <button class="action" @click="success = true">{{ $t("customer_facing.action") }}</button>
      
      <div class="alert">
        <i class="material-icons">info_outline</i>
        {{ $t("customer_facing.alert") }}
      </div>
    </div>

    <div class="success" v-else>
      <i class="material-icons">check_circle</i>
      <h1>{{ $t("customer_facing.success_title") }}</h1>
      <h3>{{ $t("customer_facing.success_legend") }}</h3>
    </div>

    <div class="customer-facing__footer">
      <div class="customer-facing__footer__language">
        <i class="material-icons">language</i>
        <select v-model="langSelect" @change="changeLanguage">
          <option value="EN">{{$t("settings.change_language.en")}}</option>
          <option value="ES">{{$t("settings.change_language.es")}}</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue';
import moment from 'moment';
import persistentData from '@/plugins/persistentData';

export default {
  setup() {
    const success = ref(false);
    const file = ref(false);
		const langSelect = ref('');
    const model = ref({
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: '',
      program: 11,
      outputs: 1
    })
    const total = computed(() => {
      const diffDates = moment(model.value.dateTo).diff(moment(model.value.dateFrom), 'd');
      return (Number(model.value.program) * Number(model.value.outputs) * diffDates) || 0;
    });

    onMounted(() => {
			langSelect.value = persistentData.get('lang') || 'EN';
		});


    const uploadFile = function (e) {
      file.value = (e.srcElement.files.length > 0);
    }

    function changeLanguage() {
			persistentData.set('lang', langSelect.value);
			window.i18n.global.locale.value = langSelect.value;
		}

    return {
      file,
      success,
      model,
      total,
      langSelect,
      uploadFile,
      changeLanguage
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.button-campaign {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

header {
  position: absolute;
  top: 16px;
  left: 16px;

	.header__logo {
		font-size: 28px;
		display: flex;
		align-items: center;
		font-family: "Roboto Condensed", sans-serif;

    i {
      margin-top: -14px;
      font-size: 40px;
    }
	}
}
.customer-facing {
  max-width: 100vw;
  min-height: 100vh;
	background: url('~@/assets/images/Elipse60.svg') 120% -200px no-repeat, linear-gradient(180deg, #7763FF 0%, #3C3280 100%);
	background-blend-mode: multiply;
	display: flex;
  flex-direction: column;
	justify-content: center;
	align-items: center;
  padding-top: 100px;

  &__container {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    max-width: 400px;
    .title {
      color: white;
      font-size: 48px;
      margin: 0 auto 24px;
      position: relative;
      width: fit-content;
  
      span {
        position: relative;
        top: 0;
        padding-right: 8px;
        font-weight: bold;
      }
  
      &:before {
        content: '';
        height: 40px;
        width: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: - 4px;
        background: #e98286;
      }
    }
    .subtitle {
      align-self: left;
      color: white;
      font-size: 20px;
      margin: 12px 0 0 -12px;
      // margin: 0 auto 12px;
      position: relative;
      width: fit-content;
  
      span {
        position: relative;
        top: 0;
        padding-right: 8px;
        font-weight: bold;
      }
  
      &:before {
        content: '';
        height: 16px;
        width: 100%;
        display: block;
        position: absolute;
        left: -4px;
        top: -4px;
        background: #e98286;
      }
    }
    .input {
      display: flex;
      flex-direction: column;
      width: 400px;
      
      label {
        font-weight: bold;
        margin: 4px 0;
      }
    
      input, select {
        background: none;
        border: 1px solid $primary-color;
        border-radius: 8px;
        height: 48px;
        @include font-settings(milli,base);
        font-family: $font-roboto;
        margin-bottom: 16px;
        width: 100%;
        color: $primary-color;
        padding: 0 16px;
        outline: none;
        // appearance: none;
        
        option {
          color: black;
        }
      
        &:focus {
          border: 1px solid #82E9DE;
        } 
      
        &::placeholder {
          color: rgba(999,999,999,.8);
        }
      
        &.has-error {
          border-color: tomato;
        }
      }

      select {
        appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 12px);
        background-position-y: 10px;
      }
    }
    .checkbox {
      height: 20px;
      margin-top: 8px;
      margin-bottom: 8px;

      label {
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        overflow-x: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        display: flex;
        align-items: center;
      }
      
      input {
        margin: 0 8px 0 0;
      }

      input[type="checkbox"] {
        appearance: none;
        width: 20px;
        height: 20px;
        background: none;
        border: 1px solid $primary-color;
        border-radius: 2px;
        position: relative;
        flex-shrink: 0;

        &:checked {
          background-color: $primary-color;
          
          &:after {
            content: 'check';
            font-family: 'Material icons';
            font-size: 18px;
            position: absolute;
          }
        }

      }
    }

    .file {
      background-color: white;
      color: #6150cc;
      padding: 12px 24px;
      text-align: center;
      border-radius: 0.3rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    .action {
      font-family: $font-roboto-cond;
			height: 48px;
			font-size: 16px;
			line-height: 20px;
			text-transform: uppercase;
			border-radius: 24px;
			background: #82e9de;
			color: black;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border: none;
			margin-bottom: 16px;
			margin-top: 48px;
			// max-width: 300px;
			width: 100%;
			cursor: pointer;
			outline: 0;
	
			&:hover, &:disabled {
				opacity: .5;
			}
    }

    .alert {
      border: 0;
      border-left: 8px solid #e98286;
      background: white;
      color: black;
      i {
        color: #e98286;
      }
    }

  }
  .success {
    background: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: black;
    padding: 12px 24px;
    border-radius: 12px;
    gap: 12px;

    i {
      color: #4caf50;
      font-size: 60px;
    }

    h3 {
      opacity: .8;
    }

    * {
      margin: 0;
    }
  }
  
  &__footer {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 48px;
    // background: black;
    width: 100%;
		
		&__language {
			display: flex;
			align-items: center;
			position: absolute;
			color: white;
			right: 16px;
			bottom: 12px;

			i {
				position: absolute;
				left: 12px;
			}

			select {
				background-color: transparent;
				border: none;
				outline: 0;
				width: 100%;
				padding: 0;
				color: white;
				-webkit-box-shadow: none;
				position: relative;
				box-shadow: none;
				font-size: 14px;
				font-family: inherit;
				line-height: 1;
				background-image: none;
				height: 24px;
				padding: 0 16px 0 40px;
				margin-left: 8px;

				option {
					color: black;
				}

				&::placeholder {
					color: white;
				}
			}
		}
  }
}

</style>