<template>
  <div class="top-navbar">
    <div class="top-navbar__icon">
      <div class="top-navbar__logo">SPOTM<i class="material-icons">cell_tower</i>STER</div>
      <!-- <small>Version 0.123456789</small> -->
    </div>

    <div class="top-navbar__left">
      <div class="top-navbar__left__station" @click.stop.prevent="showStationMenu = !showStationMenu">
        <span><i class="material-icons">radio</i>{{multimediaSelect && multimediaSelect.name}}</span>

        <div class="top-navbar__left__station--dropdown" v-show="showStationMenu">
          <template v-for="multimedia in listMultimedias" :key="multimedia.id">
            <a href="#" @click.stop.prevent="changeStation(multimedia)">{{multimedia.name}}</a>
          </template>
        </div>
      </div>
      <div class="top-navbar__left__user" @click.stop.prevent="showUserMenu = !showUserMenu">
        <figure>{{userName[0]}}</figure>
        <span>{{userName}} <i class="material-icons">{{showUserMenu ? 'expand_less' : 'expand_more'}}</i></span>

        <div class="top-navbar__left__user--dropdown" v-show="showUserMenu">
          <a href="#" @click.stop.prevent="openModalChangeLanguage">{{$t("top_navbar.options.change_language")}}</a>
          <!-- <a href="#" @click.stop.prevent="openModalChangePassword">{{$t("top_navbar.options.change_password")}}</a> -->
          <a href="#" @click.stop.prevent="logout">{{$t("top_navbar.options.logout")}}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, onMounted } from '@vue/runtime-core';
import persistentData from '@/plugins/persistentData';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const showUserMenu = ref(false);
    const showStationMenu = ref(false);
    const userName = ref("User");
    const listMultimedias = ref([]);
    const multimediaSelect = computed(() => store.getters.manageUser_multimedia);

    async function openModalChangeLanguage() {
      showUserMenu.value = false;
      await store.dispatch('modal_setActive', {active: 'CHANGE_LANGUAGE', props: {}});
    }

    async function changeStation(multimedia) {
      await store.dispatch('manageUser_setMultimedia', multimedia);
      multimediaSelect.value = multimedia;
      showStationMenu.value = false;
    }

    function logout() {
      store.dispatch("manageUser_disabled");
      router.push('/login');
    }

    onMounted(() => {
      const {fullName, multimedias} = persistentData.get('user', true);
      userName.value = fullName;
      listMultimedias.value = multimedias;
      changeStation(listMultimedias.value[0]);
    })

    return {
      showUserMenu,
      showStationMenu,
      userName,
      listMultimedias,
      multimediaSelect,
      openModalChangeLanguage,
      changeStation,
      logout
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/_variables.scss';

.top-navbar {
	height: 64px;
	font-family: $font-roboto-cond;
  grid-area: top-bar;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
		font-size: 28px;
		display: flex;
		align-items: center;
		font-family: "Roboto Condensed", sans-serif;

    i {
      margin-top: -14px;
      font-size: 40px;
    }
	}

  &__icon {
    display: flex;
    align-items: center;
    padding-left: 16px;
    
    img {
      width: 104px;
      margin-left: 16px;
    }

    small {
      margin-left: 16px;
      font-family: $font-roboto;
      background: $dark-gray-color;
      border-radius: 10px;
      padding: 2px 8px;
      font-size: 11px;    
      width: 88px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      transition: .3s ease-in-out width;
      cursor: pointer;

      &:hover {
        width: 100%;
      }
    }
  }

  &__left {
    display: flex;

    &__user, &__station {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: 16px;
      padding: 4px 8px 4px 4px;
      background: $darker-color;
      height: 40px;
      font-size: $font-size-medium;
      line-height: 40px;
      border-radius: 20px;
      text-align: right;
      cursor: pointer;
      user-select: none;
      font-weight: 700;
      font-family: $font-roboto;

      figure {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $primary-color;
        color: $darker-color;
        font-family: $font-roboto;
        text-transform: uppercase;
        font-weight: 700;
        appearance: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 8px;
      }
      
      &--dropdown {
        background: $light-gray-color;
        border-radius: 4px;
        position: absolute;
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.4);
        z-index: 1;
        padding: 16px;
        min-width: 200px;
        text-align: left;
        top: 48px;
        right: 0;

        a {
          text-decoration: none;
          color: white;
          display: block;
          line-height: 1;
          font-family: $font-roboto;
          font-size: 16px;
          padding: 8px;

          &:hover {
            color: $secondary-color;
          }
        }
      }

      > span {
        text-decoration: none;
        color: white;
        font-size: 14px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .icon-person_outline {
        position: relative;
        top: 4px;
      }
    }

    &__station {
      background: none;
      border-radius: 4px;
      
      span {
        font-size: 16px;
        i {
          font-size: 20px;
        }
      }
    }
  }
}
</style>