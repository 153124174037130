<template>
  <div class="component-modal" id="modal">
    <div class="form" v-on:submit.prevent>
      <legend>{{ $t("modal.common.closeTab.title") }}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>

      <h3 class="component-modal__question">{{ $t("modal.common.closeTab.question") }}</h3>
      
      <button type="button" @click.stop.prevent="saveAndClose">
        {{ $t('modal.confirm') }}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{ $t("modal.cancel") }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import eventBus from '@/plugins/eventBus';

export default {
  setup() {
    const store = useStore();
    const props = computed(() => store.getters.modal_getProps);
    
    async function closeModal() {
      await store.dispatch('modal_setActive');
    }

    async function saveAndClose() {
      eventBus.$emit(`removeTab`, props.value);
      closeModal();
    }

    return {
      saveAndClose,
      closeModal
    }
  }
};
</script>
<style lang="scss">
  h3 {
    text-align: center;
  }
  .item-detail {
    background: 
    ul {
      text-decoration: none;
    }
  }
</style>
