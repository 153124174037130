import interceptor from '@/plugins/interceptor';
const pathController = '/PayMethod'

export default {
  state: () => ({
    method: []
  }),
  getters: {
    payMethod_method: state => filter => state.method.filter(method => {
      if (filter.code && filter.description) return method.code.toLowerCase().includes(filter.code.toLowerCase()) && method.description.toLowerCase().includes(filter.description.toLowerCase())
      if (filter.description) return method.description.toLowerCase().includes(filter.description.toLowerCase())
      if (filter.code) return method.code.toLowerCase().includes(filter.code.toLowerCase())
      return true;
    }),
  },
  mutations: { 
    payMethod_setMethod(state, value) {
      state.method = value;
    },
    payMethod_updateMethod(state, model) {
      const idx = state.method.findIndex(p => p.id == model.id);
      state.method[idx] = model;
    },
    payMethod_addMethod(state, model) {
      state.method.push(model);
    },
    payMethod_deleteMethod(state, id) {
      const idx = state.method.findIndex(p => p.id == id);
      state.method.splice(idx, 1);
    }
  },
  actions: {
    async payMethod_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('payMethod_setMethod', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.commercial_config.pay_method'), error: true});
        throw new Error(error);
      }
    },
    async payMethod_create({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        commit('payMethod_addMethod', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.commercial_config.pay_method')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.pay_method'), error: true});
        throw new Error(error);
      }
    },
    async payMethod_update({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        commit('payMethod_updateMethod', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.commercial_config.pay_method')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.pay_method'), error: true});
        throw new Error(error);
      }
    }, 
    async payMethod_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?payMethodId=${model.id}`);
        commit('payMethod_deleteMethod', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.commercial_config.pay_method')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.pay_method'), error: true});
        throw new Error(error);
      }
    },
  }
}

