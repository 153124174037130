<template>
  <div class="component-modal" id="modal">
    <form class="form" v-on:submit.prevent>
      <legend>{{props ? `${$t("modal.commercial_config.account_executive.edit")} ${props.name}` : $t("modal.commercial_config.account_executive.create")}}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>
      
      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="text" name="code" id="code" autocomplete="off" :class="{'invalid': submitted && v$.model.code.$invalid}"
            :placeholder="$t('modal.placeholder_insert') + $t('modal.commercial_config.account_executive.code')" v-model="model.code">
          <label for="code">{{$t("modal.commercial_config.account_executive.code")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="text" name="name" id="name" autocomplete="off" :class="{'invalid': submitted && v$.model.name.$invalid}"
            :placeholder="$t('modal.placeholder_insert') + $t('modal.commercial_config.account_executive.name')" v-model="model.name">
          <label for="name">{{$t("modal.commercial_config.account_executive.name")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="text" name="address" id="address" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.commercial_config.account_executive.address')" v-model="model.address">
          <label for="address">{{$t("modal.commercial_config.account_executive.address")}}</label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="text" name="phone" id="phone" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.commercial_config.account_executive.phone')" v-model="model.phone">
          <label for="phone">{{$t("modal.commercial_config.account_executive.phone")}}</label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="text" name="taxCode" id="taxCode" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.commercial_config.account_executive.taxCode')" v-model="model.taxCode">
          <label for="taxCode">{{$t("modal.commercial_config.account_executive.taxCode")}}</label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="text" name="email" id="email" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.commercial_config.account_executive.email')" v-model="model.email">
          <label for="email">{{$t("modal.commercial_config.account_executive.email")}}</label>
        </div>
      </div>

        <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="text" name="comments" id="comments" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.commercial_config.account_executive.comments')" v-model="model.comments">
          <label for="comments">{{$t("modal.commercial_config.account_executive.comments")}}</label>
        </div>
      </div>
    
      <button type="button" @click.stop.prevent="saveAndClose">
        {{ props ? $t("modal.save") : $t('modal.create') }}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{ $t("modal.cancel") }}
      </button>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { required, maxLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
  setup() {
		const v$ = useVuelidate();
    const store = useStore();
    const props = computed(() => store.getters.modal_getProps);
    const submitted = ref(false);
    const model = ref({
      code: '',
      name: '',
      address: '',
      phone: '',
      taxCode: '',
      email: '',
      comments: ''
    })

    onMounted(() => {
      if (props.value) model.value = Object.assign({}, props.value);
    })

    async function closeModal() {
      await store.dispatch('modal_setActive');
      submitted.value = false;
    }

    async function saveAndClose() {
      submitted.value = true;
      if (!v$.value.model.$invalid) {
        try {
          await store.dispatch(props.value ? 'accountExecutive_update' : 'accountExecutive_create', model.value);
          this.closeModal();
        } catch (error) {
          console.log(error);
        }
      }
    }

    return {
      v$,
      props,
      model,
      submitted,
      saveAndClose,
      closeModal
    }
  },
	validations () {
    return {
      model: {
				code: {required, maxLengthValue: maxLength(255)},
				name: {required, maxLengthValue: maxLength(255)}
			}
    }
  }
};
</script>