<template>
  <div class="component-modal" id="modal">
    <form class="form" v-on:submit.prevent>
      <legend>{{props ? `${$t("modal.station_config.programs.edit")}` : $t("modal.station_config.programs.create")}}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>
      
      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <select name="program" id="program" v-model="model.showId" :class="{'invalid': submitted && v$.model.showId.$invalid}" >
            <option value="" selected>{{$t("modal.placeholder_select")}} {{$t("modal.station_config.programs.program")}}</option>
            <option v-for="program in listPrograms" :value="program.id" :key="program.id" :disabled="existProgram(program.id)">{{`${program.code} - ${program.name}`}}</option>
          </select>
          <label for="program">{{$t("modal.station_config.programs.program")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="time" v-model="model.timeStart" @change="validate('start')" :class="{'invalid': submitted && (v$.model.timeStart.$invalid || showExist || errorTime)}">
          <label for="start">{{$t("modal.station_config.programs.start_hour")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="time" v-model="model.timeEnd" @change="validate('end')" :class="{'invalid': submitted && (v$.model.timeEnd.$invalid || showExist || errorTime)}">
          <label for="end">{{$t("modal.station_config.programs.end_hour")}} <small>*</small></label>
        </div>
      </div>

      <!-- Alert validation -->
      <div class="alert warning" v-if="showExist">
        <i class="material-icons">info_outline</i>
        {{$t("modal.station_config.schedules.showSameHour")}}
      </div>
      <div class="alert warning" v-if="errorTime">
        <i class="material-icons">info_outline</i>
        {{$t("modal.station_config.schedules.errorTime")}}
      </div>
    
      <button type="button" @click.stop.prevent="saveAndClose">
        {{ props ? $t("modal.save") : $t('modal.create') }}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{ $t("modal.cancel") }}
      </button>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { required } from '@vuelidate/validators';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';

export default {
  setup() {
		const v$ = useVuelidate();
    const store = useStore();
    const route = useRoute();
    const props = computed(() => store.getters.modal_getProps);
    const listPrograms = computed(() => store.getters.show_programs({code: '', name: ''}));
    const schedule = computed(() => store.getters.schedule_getSchedule(route.params.id));
    const loadForm = ref(false);
    const showExist = ref(null);
    const errorTime = ref(false);
    const submitted = ref(false);
    const model = ref({
      showId: '',
      timeStart: '00:00',
      timeEnd: '00:00'
    })

    onMounted(() => {
      if (props.value) model.value = Object.assign({}, props.value);
      loadForm.value = true;
    })

    async function closeModal() {
      await store.dispatch('modal_setActive');
      submitted.value = false;
    }

    async function saveAndClose() {
      submitted.value = true;
      if (!v$.value.model.$invalid && !showExist.value && !errorTime.value) {
        try {
          await store.dispatch('schedule_addShows', {...model.value, tabId: route.params.id});
          await store.dispatch('tabs_setPropChanges', {id: route.params.id, changes: true});
          this.closeModal();
        } catch (error) {
          console.log(error);
        }
      }
    }

    function validate(type) {
      function _getMoment(time) {
        const [start, end] = time.split(":");
        return moment().hour(start).minutes(end);
      }

      const startTimeValidate = _getMoment(model.value.timeStart);
      const endTimeValidate = _getMoment(model.value.timeEnd);

      // Validar si el horario ingresado esta dentro de otro show
      showExist.value = schedule.value.shows.find(show => {
        if (props.value && props.value.id == show.id) return false; 
        
        let isBetween = false; 
        let otherIsBetween = false;
        if (type == 'start') {
          isBetween = moment(startTimeValidate).isBetween(_getMoment(show.timeStart), _getMoment(show.timeEnd)) || show.timeStart == model.value.timeStart; 
        } else {
          isBetween = moment(endTimeValidate).isBetween(_getMoment(show.timeStart), _getMoment(show.timeEnd)) || show.timeEnd == model.value.timeEnd; 
        }
        // Validar si otro show esta dentro del rango cargado
        if (model.value.timeStart != '' && model.value.timeEnd != '') {
          otherIsBetween = moment(_getMoment(show.timeStart)).isBetween(startTimeValidate, endTimeValidate);
          if (!otherIsBetween) otherIsBetween = moment(_getMoment(show.timeEnd)).isBetween(startTimeValidate, endTimeValidate);
        }

        // Retornar la validación
        return isBetween || otherIsBetween;
      });
      

      if (model.value.timeStart != '' && model.value.timeEnd != '') {
        // Validar que start sea distinto a end
        if (model.value.timeStart == model.value.timeEnd) {
          errorTime.value = true;
          return;
        }
        // Validar que start sea previo a end
        const startTimeValidate = _getMoment(model.value.timeStart);
        const endTimeValidate = _getMoment(model.value.timeEnd);
        errorTime.value = moment(startTimeValidate).isAfter(endTimeValidate);
      }
    }

    function existProgram(id) {
      return (schedule.value.shows.find((show => show.showId == id)))
    }

    return {
      v$,
      props,
      model,
      loadForm,
      listPrograms,
      showExist,
      submitted,
      errorTime,
      saveAndClose,
      closeModal,
      validate,
      existProgram
    }
  },
	validations () {
    return {
      model: {
        showId: {required},
        timeStart: {required},
        timeEnd: {required}
			}
    }
  }
};
</script>