import interceptor from '@/plugins/interceptor';
const pathController = '/AdAgencyGroup';

export default {
  state: () => ({
    agencies: []
  }),
  getters: {
    adAgencyGroup_agencies: state => filter => state.agencies.filter(agency => {
      if (filter.name && filter.code) return agency.name.toLowerCase().includes(filter.name.toLowerCase()) && agency.code.toLowerCase().includes(filter.code.toLowerCase())
      if (filter.name) return agency.name.toLowerCase().includes(filter.name.toLowerCase())
      if (filter.code) return agency.code.toLowerCase().includes(filter.code.toLowerCase())
      return true;
    }),
  },
  mutations: { 
    adAgencyGroup_setAgencies(state, value) {
      state.agencies = value;
    },
    adAgencyGroup_updateAgencies(state, model) {
      const idx = state.agencies.findIndex(p => p.id == model.id);
      state.agencies[idx] = model;
    },
    adAgencyGroup_addAgencies(state, model) {
      state.agencies.push(model);
    },
    adAgencyGroup_deleteAgencies(state, id) {
      const idx = state.agencies.findIndex(p => p.id == id);
      state.agencies.splice(idx, 1);
    }
  },
  actions: {
    async adAgencyGroup_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('adAgencyGroup_setAgencies', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.commercial_config.ad_agency_group'), error: true});
        throw new Error(error);
      }
    },
    async adAgencyGroup_create({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        commit('adAgencyGroup_addAgencies', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.commercial_config.ad_agency_group')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.ad_agency_group'), error: true});
        throw new Error(error);
      }
    },
    async adAgencyGroup_update({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        commit('adAgencyGroup_updateAgencies', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.commercial_config.ad_agency_group')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.ad_agency_group'), error: true});
        throw new Error(error);
      }
    }, 
    async adAgencyGroup_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?adAgencyGroupId=${model.id}`);
        commit('adAgencyGroup_deleteAgencies', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.commercial_config.ad_agency_group')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.ad_agency_group'), error: true});
        throw new Error(error);
      }
    },
  }
}

