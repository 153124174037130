import interceptor from '@/plugins/interceptor';
const pathController = '/Origin'

export default {
  state: () => ({
    origin: []
  }),
  getters: {
    origin_origin: state => filter => state.origin.filter(origin => {
      if (filter.code && filter.description) return origin.code.toLowerCase().includes(filter.code.toLowerCase()) && origin.description.toLowerCase().includes(filter.description.toLowerCase())
      if (filter.description) return origin.description.toLowerCase().includes(filter.description.toLowerCase())
      if (filter.code) return origin.code.toLowerCase().includes(filter.code.toLowerCase())
      return true;
    }),
  },
  mutations: { 
    origin_setOrigin(state, value) {
      state.origin = value;
    },
    origin_updateOrigin(state, model) {
      const idx = state.origin.findIndex(p => p.id == model.id);
      state.origin[idx] = model;
    },
    origin_addOrigin(state, model) {
      state.origin.push(model);
    },
    origin_deleteOrigin(state, id) {
      const idx = state.origin.findIndex(p => p.id == id);
      state.origin.splice(idx, 1);
    }
  },
  actions: {
    async origin_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('origin_setOrigin', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.commercial_config.origin'), error: true});
        throw new Error(error);
      }
    },
    async origin_create({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        commit('origin_addOrigin', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.commercial_config.origin')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.origin'), error: true});
        throw new Error(error);
      }
    },
    async origin_update({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        commit('origin_updateOrigin', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.commercial_config.origin')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.origin'), error: true});
        throw new Error(error);
      }
    }, 
    async origin_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?originId=${model.id}`);
        commit('origin_deleteOrigin', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.commercial_config.origin')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.origin'), error: true});
        throw new Error(error);
      }
    },
  }
}

