import interceptor from '@/plugins/interceptor';
const pathController = '/AdAgency';

export default {
  state: () => ({
    agencies: []
  }),
  getters: {
    adAgency_agencies: state => filter => state.agencies.filter(agency => {
      // Obtener los que tienen valor
      const filtersActives = {
        code: (filter.code != ''),
        name: (filter.name != ''),
        adAgencyGroupId: (filter.adAgencyGroupId != ''),
        taxCode: (filter.taxCode != '')
      };
      // Consultar solo por los activos
      let valid = true;
      Object.keys(filtersActives).forEach(active => {
        if (filtersActives[active] && valid) {
          valid = `${agency[active]}`.toLowerCase().includes(`${filter[active]}`.toLowerCase());
        }
      });

      return valid;
    }),
  },
  mutations: { 
    adAgency_setAgencies(state, value) {
      state.agencies = value;
    },
    adAgency_updateAgencies(state, model) {
      const idx = state.agencies.findIndex(p => p.id == model.id);
      state.agencies[idx] = model;
    },
    adAgency_addAgencies(state, model) {
      state.agencies.push(model);
    },
    adAgency_deleteAgencies(state, id) {
      const idx = state.agencies.findIndex(p => p.id == id);
      state.agencies.splice(idx, 1);
    }
  },
  actions: {
    async adAgency_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('adAgency_setAgencies', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.commercial_config.ad_agency'), error: true});
        throw new Error(error);
      }
    },
    async adAgency_create({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        commit('adAgency_addAgencies', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.commercial_config.ad_agency')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.ad_agency'), error: true});
        throw new Error(error);
      }
    },
    async adAgency_update({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        commit('adAgency_updateAgencies', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.commercial_config.ad_agency')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.ad_agency'), error: true});
        throw new Error(error);
      }
    }, 
    async adAgency_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?adAgencyId=${model.id}`);
        commit('adAgency_deleteAgencies', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.commercial_config.ad_agency')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.ad_agency'), error: true});
        throw new Error(error);
      }
    },
  }
}

