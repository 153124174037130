import interceptor from '@/plugins/interceptor';
const pathController = '/Authentication'

export default {
  state: () => ({
  }),
  mutations: { 
  },
  actions: {
    async authentication_authenticate(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_URL}${pathController}/authenticate`, model);
    },
    async authentication_refreshToken(_, token) {
      return await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/refreshToken`, token);
    },
    async authentication_insoftAuthenticate(_, token) {
      return await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/insoftAuthenticate`, {value: token});
    }
    // async authentication_register(_, model) {
    //     return await interceptor.anonymous.post(`${process.env.VUE_APP_API_URL}${pathController}/register`, model);
    // },
    // async authentication_userActivate(_, model) {
    //   return await interceptor.anonymous.post(`${process.env.VUE_APP_API_URL}${pathController}/userActivate`, model);
    // },
  }
}

