<template>
  <div class="component-modal modal-large" id="modal">
    <form class="form" v-on:submit.prevent>
      <legend>{{$t("modal.spot_programming.spot.simpleSchedule.title")}}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>

      <div class="component-modal__row--half">
        <div class="component-modal__column--half">
          <div class="component-modal-input component-modal-input--rename">
            <select name="spotType" id="spotType" v-model="model.spotType" @change="changeSpotType(model.spotType)">
              <option :value="0">{{$t("modal.spot_programming.spot.options.spotType.audio")}}</option> <!-- Sin NTC -->
              <option :value="1">{{$t("modal.spot_programming.spot.options.spotType.nonTraditionalSpot")}}</option>
              <option :value="2">{{$t("modal.spot_programming.spot.options.spotType.sponsor")}}</option> <!-- Sin NTC -->
            </select>
            <label for="spotType">{{$t("modal.spot_programming.spot.simpleSchedule.spotType")}}</label>
          </div>
        </div>
        <div class="component-modal__column--half">
          <div class="component-modal-input component-modal-input--rename">
            <select name="position" id="position" v-model="model.commercialBreakSpotPosition" v-if="model.spotType != 2" >
              <option :value="null">{{$t("modal.placeholder_select")}}{{$t("modal.spot_programming.spot.simpleSchedule.spotPosition")}}</option>
              <option :value="1">{{$t("modal.spot_programming.spot.options.spotPosition.first")}}</option>
              <option :value="2">{{$t("modal.spot_programming.spot.options.spotPosition.second")}}</option>
              <option :value="3">{{$t("modal.spot_programming.spot.options.spotPosition.third")}}</option>
              <option :value="4">{{$t("modal.spot_programming.spot.options.spotPosition.fourth")}}</option>
              <option :value="5">{{$t("modal.spot_programming.spot.options.spotPosition.fifty")}}</option>
              <option :value="6">{{$t("modal.spot_programming.spot.options.spotPosition.indistict")}}</option>
              <option :value="7">{{$t("modal.spot_programming.spot.options.spotPosition.penultimate")}} {{$t("modal.spot_programming.spot.options.spotPosition.fourth")}}</option>
              <option :value="8">{{$t("modal.spot_programming.spot.options.spotPosition.penultimate")}} {{$t("modal.spot_programming.spot.options.spotPosition.third")}}</option>
              <option :value="9">{{$t("modal.spot_programming.spot.options.spotPosition.penultimate")}} {{$t("modal.spot_programming.spot.options.spotPosition.second")}}</option>
              <option :value="10">{{$t("modal.spot_programming.spot.options.spotPosition.penultimate")}} {{$t("modal.spot_programming.spot.options.spotPosition.first")}}</option>
              <option :value="11">{{$t("modal.spot_programming.spot.options.spotPosition.last")}}</option>
            </select>
            <input v-else type="number" name="position" id="position" v-model="model.commercialBreakSpotPosition" :placeholder="$t('modal.spot_programming.spot.simpleSchedule.spotPostion')">
            <label for="position">{{$t("modal.spot_programming.spot.simpleSchedule.spotPosition")}}</label>
          </div>
        </div>
      </div>
  
      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="text" name="filename" id="filename" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.spot_programming.spot.simpleSchedule.spotFileName')" v-model="model.filename" :class="{'invalid': submitted && v$.model.filename.$invalid}">
          <label for="filename">{{$t("modal.spot_programming.spot.simpleSchedule.spotFileName")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row--half">
        <div class="component-modal__column--half">
          <div class="component-modal-input component-modal-input--rename">
            <input type="number" name="length" id="length" autocomplete="off" :placeholder="$t('modal.placeholder_insert')" v-model="model.length" :class="{'invalid': submitted && v$.model.length.$invalid}">
            <label for="length">
              {{$t("modal.spot_programming.spot.simpleSchedule.length")}} <small>*</small>
              <small class="time" v-if="model.length > 0">{{calculateTime(model.length)}}</small>
            </label> 
          </div>
        </div>

        <div class="component-modal__column--half">
          <div class="component-modal-input component-modal-input--rename">
            <select name="speaker" id="speaker" v-model="model.speakerId">
              <option :value="null">{{$t("modal.placeholder_select")}}{{$t("modal.spot_programming.spot.simpleSchedule.speaker")}}</option>
              <option v-for="speakers in listspeakers" :key="speakers.id" :value="speakers.id">{{speakers.code}} - {{speakers.name}}</option>
            </select>
            <label for="speaker">{{$t("modal.spot_programming.spot.simpleSchedule.speaker")}}</label>
          </div>
        </div>
      </div>


      <div class="component-modal__row--third">
        <div class="component-modal__column--third">
          <div class="component-modal-input component-modal-input--rename">
            <input type="date" name="startDate" id="startDate" autocomplete="off" :placeholder="$t('modal.placeholder_insert')" v-model="model.startDate" :class="{'invalid': submitted && v$.model.startDate.$invalid}">
            <label for="startDate">{{$t("modal.spot_programming.spot.simpleSchedule.startDate")}} <small>*</small></label>
          </div>
        </div>

        <div class="component-modal__column--third">
          <div class="component-modal-input component-modal-input--rename">
            <input type="date" name="endDate" id="endDate" autocomplete="off" :placeholder="$t('modal.placeholder_insert')" v-model="model.endDate" :min="model.startDate">
            <label for="endDate">{{$t("modal.spot_programming.spot.simpleSchedule.endDate")}}</label>
          </div>
        </div>

        <div class="component-modal__column--third">
          <div class="component-modal-input component-modal-input--rename">
            <input type="text" name="timeFee" id="timeFee" autocomplete="off" :placeholder="$t('modal.placeholder_insert')" v-model="model.timeFee">
            <label for="timeFee">{{$t("modal.spot_programming.spot.simpleSchedule.timeFee")}}</label>
          </div>
        </div>
      </div>


      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <textarea :disabled="model.spotType == 0 || model.spotType == 2" v-model="model.ntcText" name="NTCText" id="NTCText" cols="30" rows="3" :placeholder="$t('modal.placeholder_insert') + $t('modal.spot_programming.spot.simpleSchedule.NTCText')"></textarea>
          <label for="NTCText">{{$t("modal.spot_programming.spot.simpleSchedule.text")}}</label>
        </div>
      </div>

      <!-- Days of week  -->
      <div class="component-modal__row">
        {{ $t("modal.spot_programming.spot.simpleSchedule.daysToSchedule") }} <small style="color: tomato">*</small>
        <div style="display:flex; justify-content: space-between;">
          <div class="component-modal-checkbox">
            <label>
              <input type="checkbox" v-model="model.monday">
              {{ $t("days_of_week.monday") }}
            </label>
          </div>
          <div class="component-modal-checkbox">
            <label>
              <input type="checkbox" v-model="model.tuesday">
              {{ $t("days_of_week.tuesday") }}
            </label>
          </div>
          <div class="component-modal-checkbox">
            <label>
              <input type="checkbox" v-model="model.wednesday">
              {{ $t("days_of_week.wednesday") }}
            </label>
          </div>
          <div class="component-modal-checkbox">
            <label>
              <input type="checkbox" v-model="model.thursday">
              {{ $t("days_of_week.thursday") }}
            </label>
          </div>
          <div class="component-modal-checkbox">
            <label>
              <input type="checkbox" v-model="model.friday">
              {{ $t("days_of_week.friday") }}
            </label>
          </div>
          <div class="component-modal-checkbox">
            <label>
              <input type="checkbox" v-model="model.saturday">
              {{ $t("days_of_week.saturday") }}
            </label>
          </div>
          <div class="component-modal-checkbox">
            <label>
              <input type="checkbox" v-model="model.sunday">
              {{ $t("days_of_week.sunday") }}  
            </label>
          </div>
        </div>
      </div>

      <div class="component-modal-section">

        <div class="component-modal__row">
          <div class="component-modal-input component-modal-input--rename">
            <select name="" id="" v-model="model.timeMode">
              <option :value="0">{{$t('modal.spot_programming.spot.simpleSchedule.type_timeInterval')}}</option>
              <option :value="1">{{$t('modal.spot_programming.spot.simpleSchedule.type_show')}}</option>
              <option :value="2">{{$t('modal.spot_programming.spot.simpleSchedule.type_specificBatch')}}</option>
            </select>
            <label for="name">{{$t('modal.spot_programming.spot.simpleSchedule.type')}}</label>
          </div>
        </div>
    
        <!-- By time interval -->
        <div class="component-modal__row--third" v-if="model.timeMode == 0">
          <div class="component-modal__column--third">
            <div class="component-modal-input component-modal-input--rename">
              <input type="time" v-model="model.startTime" @change="validateTimes" @focus="$event.target.select()" :class="{'invalid': submitted && model.timeMode == 0 && model.startTime == '' || invalidDates}">
              <label>{{$t('modal.spot_programming.spot.simpleSchedule.startTime')}} <small>*</small></label>
            </div>
          </div>
          <div class="component-modal__column--third">
            <div class="component-modal-input component-modal-input--rename">
              <input type="time" v-model="model.endTime" @change="validateTimes" @focus="$event.target.select()" :class="{'invalid': submitted && model.timeMode == 0 && model.endTime == '' || invalidDates}">
              <label>{{$t('modal.spot_programming.spot.simpleSchedule.endTime')}} <small>*</small></label>
            </div>
          </div>
          <div class="component-modal__column--third">
            <div class="component-modal-input component-modal-input--rename">
              <input type="number" name="occurrences" id="occurrences" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.spot_programming.spot.simpleSchedule.occurrences')" v-model="model.occurrences" :class="{'invalid': submitted && model.timeMode == 0 && model.occurrences == 0}">
              <label for="occurrences">{{$t('modal.spot_programming.spot.simpleSchedule.occurrences')}} <small>*</small></label>
            </div>
          </div>
        </div>
        <template v-if="model.timeMode == 0 && model.startTime != '' && model.endTime != ''">
          <!-- Alert validation -->
          <div class="alert warning" v-if="invalidDates">
            <i class="material-icons">info_outline</i>
            {{$t("modal.station_config.schedules.errorTime")}}
          </div>
        </template>

        <!-- By show -->
        <div v-if="model.timeMode == 1">
          <div class="component-modal__row--half">
            <div class="component-modal__column--half">
              <div class="component-modal-input component-modal-input--rename">
                <select name="show" id="show" v-model="model.showId" :class="{'invalid': submitted && model.timeMode == 1 && model.showId == null}">
                  <option :value="null">{{$t("modal.placeholder_select")}}{{$t("modal.spot_programming.spot.detailedSchedule.show")}}</option>
                  <option v-for="show in listPrograms" :key="show.id" :value="show.id">{{show.code}} - {{show.name}}</option>
                </select>
                <label for="show">{{$t("modal.spot_programming.spot.detailedSchedule.show")}} <small>*</small></label>
              </div>
            </div>
            <div class="component-modal__column--half">
              <div class="component-modal-input component-modal-input--rename">
                <input type="number" name="occurrences" id="occurrences" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.spot_programming.spot.simpleSchedule.occurrences')" v-model="model.occurrences" :class="{'invalid': submitted && model.timeMode == 1 && model.occurrences == 0}">
                <label for="occurrences">{{$t('modal.spot_programming.spot.simpleSchedule.occurrences')}} <small>*</small></label>
              </div>
            </div>
          </div>
        </div>
        
        <!-- By specific batch -->
        <div v-if="model.timeMode == 2"> 
          <div class="component-modal__row">
            <div class="component-modal-input component-modal-input--rename">
              <input type="time" v-model="valueExactTimes" @keyup.enter="$event.target.blur(); $event.target.focus()" @focus="$event.target.select()" :class="{'invalid': submitted && model.timeMode == 2 && arrExactTimes.length == 0}">
              <label for="name">{{$t('modal.spot_programming.spot.simpleSchedule.time')}} <small>*</small></label>
            </div>
            <button class="button-add" @click="addHour(valueExactTimes)">{{$t('modal.spot_programming.spot.simpleSchedule.add')}}</button>
          </div>

          <ul class="list-tabs">
            <li v-for="(times, idxTimes) in arrExactTimes" :key="times">{{times}} <i class="material-icons" @click="removeTime(idxTimes)">close</i> </li>
          </ul>
        </div>
      </div>

      <!-- Business -->
      <div class="component-modal-section">
        <strong>{{$t("modal.spot_programming.spot.simpleSchedule.business")}}</strong>
        <div class="component-modal__row--third">
          <div class="component-modal__column--third">
            <div class="component-modal-input component-modal-input--rename">
              <select name="" id="" v-model="model.idBusinessShow">
                <option :value="null">{{$t("modal.placeholder_select")}}{{$t("modal.spot_programming.spot.simpleSchedule.show")}}</option>
                <option v-for="show in listPrograms" :key="show.id" :value="show.id">{{show.code}} - {{show.name}}</option>
              </select>
              <label for="name">{{$t("modal.spot_programming.spot.simpleSchedule.show")}}</label>
            </div>
          </div>
          <div class="component-modal__column--third">
            <div class="component-modal-input component-modal-input--rename">
              <select name="businessUnit" id="businessUnit" v-model="model.businessUnitId">
                <option :value="null">{{$t("modal.placeholder_select")}}{{$t("modal.spot_programming.spot.simpleSchedule.unit")}}</option>
                <option v-for="business in listBusinessUnit" :key="business.id" :value="business.id">{{business.code}} - {{business.description}}</option>
              </select>
              <label for="businessUnit">{{$t("modal.spot_programming.spot.simpleSchedule.unit")}}</label>
            </div>
          </div>

          <div class="component-modal__column--third">
            <div class="component-modal-input component-modal-input--rename">
              <input type="number" name="businessMonthlyFee" id="businessMonthlyFee" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.spot_programming.spot.simpleSchedule.monthlyFee')" v-model="model.businessMonthlyFee">
              <label for="businessMonthlyFee">{{$t("modal.spot_programming.spot.simpleSchedule.monthlyFee")}}</label>
            </div>
          </div>
        </div>
      </div>

      <button type="button" @click.stop.prevent="saveAndClose">
        {{ props ? $t("modal.save") : $t('modal.create') }}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{ $t("modal.cancel") }}
      </button>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { minValue, required } from '@vuelidate/validators';
import eventBus from '@/plugins/eventBus';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';

export default {
  setup() {
    const v$ = useVuelidate();
    const store = useStore();
    const props = computed(() => store.getters.modal_getProps);
    const clientCategories = computed(() => store.getters.clientCategory_categories({name: '', code: ''}));
    const listBusinessUnit = computed(() => store.getters.businessUnit_businessUnit({code: '', description: ''}));
    const listPrograms = computed(() => store.getters.show_programs({code: '', name: ''}));
    const listspeakers = computed(() => store.getters.speaker_speaker({code: '', name: ''}));
    const calculateTime = computed(() => time => moment().startOf('day').seconds(time).format('mm:ss'))
    const submitted = ref(false);
    const invalidDates = ref(false);
    
    const model = ref({
      id: 0,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: null,
      startTime: "00:00",
      endTime: "00:00",
      occurrences: 0,
      timeMode: 0,
      showId: null,
      exactTimes: "",
      timeFee: 0,
      commercialBreakSpotPosition: 6,
      speakerId: null,
      spotType: 0,
      filename: "",
      length: 0,
      ntcText: "",
      businessUnitId: null,
      idBusinessShow: null,
      businessMonthlyFee: 0
    });
    const valueExactTimes = ref('');
    const arrExactTimes = ref([]);

    onMounted(() => {
      model.value['internalReferenceSpot'] = props.value.internalReferenceSpot;
      if (props.value.edit) {
        props.value.monday = (props.value.monday == 1);
        props.value.tuesday = (props.value.tuesday == 1);
        props.value.wednesday = (props.value.wednesday == 1);
        props.value.thursday = (props.value.thursday == 1);
        props.value.friday = (props.value.friday == 1);
        props.value.saturday = (props.value.saturday == 1);
        props.value.sunday = (props.value.sunday == 1);
        props.value.startDate = moment(props.value.startDate).format("YYYY-MM-DD");
        props.value.endDate = moment(props.value.endDate).isValid() ? moment(props.value.endDate).format("YYYY-MM-DD") : null;
        if (props.value.exactTimes != '') arrExactTimes.value = props.value.exactTimes.split(",");
        props.value.editReady = true;
        model.value = Object.assign({}, props.value);
      }
      
      store.dispatch('clientCategory_getAll');
      store.dispatch('businessUnit_getAll');
      store.dispatch('show_getAll');
      store.dispatch('speaker_getAll');
    })

    async function closeModal() {
      await store.dispatch('modal_setActive');
      submitted.value = false;
    }

    async function saveAndClose() {
      submitted.value = true;
      // Validate custom
      const validateText = () => {
        if (model.value.spotType == 1) {
          return model.value.ntcText != '';
        }
        return true;
      }
      validateTimes();
      const validateDays = (model.value.monday || model.value.tuesday || model.value.wednesday || model.value.thursday || model.value.friday || model.value.saturday || model.value.sunday)
      const validateType = () => {
        if (model.value.timeMode == 0) {
          return model.value.startTime != '' && model.value.endTime != '' && model.value.occurrences > 0 && !invalidDates.value;
        }
        if (model.value.timeMode == 1) {
          return (model.value.showId != null && model.value.occurrences > 0);
        }
        if (model.value.timeMode == 2) {
          return (arrExactTimes.value.length > 0)
        }
      };
      if (!v$.value.model.$invalid && validateText() && validateDays && validateType()) {
        // Convert value dates
        model.value.monday = model.value.monday ? 1 : 0;
        model.value.tuesday = model.value.tuesday ? 1 : 0;
        model.value.wednesday = model.value.wednesday ? 1 : 0;
        model.value.thursday = model.value.thursday ? 1 : 0;
        model.value.friday = model.value.friday ? 1 : 0;
        model.value.saturday = model.value.saturday ? 1 : 0;
        model.value.sunday = model.value.sunday ? 1 : 0;
        // Exact times es el valor de los horarios concatenados por ',';
        model.value.exactTimes = arrExactTimes.value.join(",");
  
        eventBus.$emit(props.value.edit ? 'editSimpleSchedule' : 'addSimpleSchedule', model.value);  
        this.closeModal();
      }
    }

    function changeSpotType(type) {
      if (type != 'non_traditional_spot') model.value.ntcText = '';
      if (type != 'audio') model.value.commercialBreakSpotPosition = 6;
    }

    function validateTimes() {
      function _getMoment(time) {
        const [start, end] = time.split(":");
        return moment().hour(start).minutes(end);
      }

      if (model.value.startTime != '' && model.value.endTime != '') {
        // Validar que start sea distinto a end
        if (model.value.startTime == model.value.endTime) {
          invalidDates.value = true;
          return;
        }
        // Validar que start sea previo a end
        const startTimeValidate = _getMoment(model.value.startTime);
        const endTimeValidate = _getMoment(model.value.endTime);
        invalidDates.value = moment(startTimeValidate).isAfter(endTimeValidate);
      } else { 
        invalidDates.value = false;
      }
    }

    function addHour(hour) {
      if (hour != '') {
        if (!arrExactTimes.value.includes(hour)) arrExactTimes.value.push(hour);
        valueExactTimes.value = '';
      }
    }

    function removeTime(idx) {
      arrExactTimes.value.splice(idx, 1);
    }

    return {
      v$,
      props,
      model,
      clientCategories,
      listBusinessUnit,
      listPrograms,
      listspeakers,
      arrExactTimes,
      valueExactTimes,
      calculateTime,
      submitted,
      invalidDates,
      saveAndClose,
      closeModal,
      changeSpotType,
      validateTimes,
      addHour,
      removeTime
    }
  },
  validations () {
    return {
      model: {
        startDate:{required},
        // endDate:{required},
        // startTime:{required},
        // endTime:{required},
        // occurrences:{required},
        timeMode:{required},
        // showId:{required},
        // exactTimes:{required},
        // timeFee:{required},
        // commercialBreakSpotPosition:{required},
        // speakerId:{required},
        // spotType:{required},
        filename:{required},
        length:{required, minValueValue: minValue(1)},
        // ntcText:{required},
        // businessUnitId:{required},
        // idBusinessShow:{required},
        // businessMonthlyFee:{required} 
			}
    }
  }
};
</script>
<style lang="scss" scoped>
  .button-add {
    position: absolute;
    right: 60px;
    margin-top: 8px;
  }

  label {
    overflow: hidden;
  }
  .time {
    position: absolute;
    top: 0px;
    color: black;
    background: gray;
    padding: 2px 8px;
    border-radius: 12px;
    margin-left: 4px;
    font-size: 12px;
  }
</style>