<template>
  <div class="component-modal modal-large" id="modal">
    <form class="form" v-on:submit.prevent>
      <legend>{{$t("modal.spot_programming.spot.detailedSchedule.title")}}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>

      <div class="component-modal__row--half">
        <div class="component-modal__column--half">
          <div class="component-modal-input component-modal-input--rename">
            <select name="spotType" id="spotType" v-model="model.spotType" @change="model.spotType == 0 ? model.ntcText = '' : ''">
              <option :value="0">{{$t("modal.spot_programming.spot.options.spotType.audio")}}</option>
              <option :value="1">{{$t("modal.spot_programming.spot.options.spotType.nonTraditionalSpot")}}</option>
              <option :value="2">{{$t("modal.spot_programming.spot.options.spotType.spot")}}</option>
            </select>
            <label for="spotType">{{$t("modal.spot_programming.spot.detailedSchedule.spotType")}}</label>
          </div>
        </div>
        <div class="component-modal__column--half">
          <div class="component-modal-input component-modal-input--rename">
            <select name="position" id="position" v-model="model.commercialBreakSpotPosition">
              <option :value="null">{{$t("modal.placeholder_select")}}{{$t("modal.spot_programming.spot.detailedSchedule.spotPosition")}}</option>
              <option :value="1">{{$t("modal.spot_programming.spot.options.spotPosition.first")}}</option>
              <option :value="2">{{$t("modal.spot_programming.spot.options.spotPosition.second")}}</option>
              <option :value="3">{{$t("modal.spot_programming.spot.options.spotPosition.third")}}</option>
              <option :value="4">{{$t("modal.spot_programming.spot.options.spotPosition.fourth")}}</option>
              <option :value="5">{{$t("modal.spot_programming.spot.options.spotPosition.fifty")}}</option>
              <option :value="6">{{$t("modal.spot_programming.spot.options.spotPosition.indistict")}}</option>
              <option :value="7">{{$t("modal.spot_programming.spot.options.spotPosition.penultimate")}} {{$t("modal.spot_programming.spot.options.spotPosition.fourth")}}</option>
              <option :value="8">{{$t("modal.spot_programming.spot.options.spotPosition.penultimate")}} {{$t("modal.spot_programming.spot.options.spotPosition.third")}}</option>
              <option :value="9">{{$t("modal.spot_programming.spot.options.spotPosition.penultimate")}} {{$t("modal.spot_programming.spot.options.spotPosition.second")}}</option>
              <option :value="10">{{$t("modal.spot_programming.spot.options.spotPosition.penultimate")}} {{$t("modal.spot_programming.spot.options.spotPosition.first")}}</option>
              <option :value="11">{{$t("modal.spot_programming.spot.options.spotPosition.last")}}</option>
            </select>
            <label for="position">{{$t("modal.spot_programming.spot.detailedSchedule.spotPosition")}}</label>
          </div>
        </div>
      </div>

    
      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="text" name="spotFileName" id="spotFileName" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.spot_programming.spot.detailedSchedule.spotFileName')" v-model="model.fileName"  :class="{'invalid': submitted && v$.model.fileName.$invalid}">
          <label for="spotFileName">{{$t("modal.spot_programming.spot.detailedSchedule.spotFileName")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row--half">
        <div class="component-modal__column--half">
          <div class="component-modal-input component-modal-input--rename">
            <input type="number" name="length" id="length" autocomplete="off" :placeholder="$t('modal.placeholder_insert')" v-model="model.length" :class="{'invalid': submitted && v$.model.length.$invalid}">
            <label for="length">
              {{$t("modal.spot_programming.spot.detailedSchedule.length")}} <small>*</small>
              <small class="time" v-if="model.length > 0">{{calculateTime(model.length)}}</small>
            </label>
          </div>
        </div>

        <div class="component-modal__column--half">
          <div class="component-modal-input component-modal-input--rename">
            <select name="speaker" id="speaker" v-model="model.speakerId">
              <option :value="null">{{$t("modal.placeholder_select")}}{{$t("modal.spot_programming.spot.detailedSchedule.speaker")}}</option>
              <option v-for="speaker in listspeakers" :key="speaker.id" :value="speaker.id">{{speaker.code}} - {{speaker.name}}</option>
            </select>
            <label for="speaker">{{$t("modal.spot_programming.spot.detailedSchedule.speaker")}}</label>
          </div>
        </div>
      </div>

      <div class="component-modal__row--third">
        <div class="component-modal__column--third">
          <div class="component-modal-input component-modal-input--rename">
            <input type="time" v-model="model.startTime" @change="validateTimes" @focus="$event.target.select()" :class="{'invalid': submitted && (v$.model.startTime.$invalid || invalidDates)}">
            <label for="start">{{$t("modal.spot_programming.spot.detailedSchedule.startTime")}} <small>*</small></label>
          </div>
        </div>

        <div class="component-modal__column--third">
          <div class="component-modal-input component-modal-input--rename">
            <input type="time" v-model="model.endTime" @change="validateTimes" @focus="$event.target.select()" :class="{'invalid': submitted && (v$.model.endTime.$invalid || invalidDates)}">
            <label for="end">{{$t("modal.spot_programming.spot.detailedSchedule.endTime")}} <small>*</small></label>
          </div>
        </div>

        <div class="component-modal__column--third">
          <div class="component-modal-input component-modal-input--rename">
            <input type="text" name="timeFee" id="timeFee" autocomplete="off" :placeholder="$t('modal.placeholder_insert')" v-model="model.timeFee">
            <label for="timeFee">{{$t("modal.spot_programming.spot.detailedSchedule.timeFee")}}</label>
          </div>
        </div>
      </div>

      <template v-if="model.startTime != '' && model.endTime != ''">
        <!-- Alert validation -->
        <div class="alert warning" v-if="invalidDates">
          <i class="material-icons">info_outline</i>
          {{$t("modal.station_config.schedules.checkFields")}}
        </div>
      </template>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <textarea :disabled="model.spotType == 0" name="NTCText" id="NTCText" cols="30" rows="3" :placeholder="$t('modal.placeholder_insert') + $t('modal.spot_programming.spot.detailedSchedule.text')" v-model="model.ntcText"></textarea>
          <label for="NTCText">{{$t("modal.spot_programming.spot.detailedSchedule.text")}}</label>
        </div>
      </div>

      <!-- Business -->
      <div class="component-modal-section">
        <strong>{{$t("modal.spot_programming.spot.detailedSchedule.business")}}</strong>
        <div class="component-modal__row--third">
          <div class="component-modal__column--third">
            <div class="component-modal-input component-modal-input--rename">
              <select name="" id="" v-model="model.idBusinessShow">
                <option :value="null">{{$t("modal.placeholder_select")}}{{$t("modal.spot_programming.spot.detailedSchedule.show")}}</option>
                <option v-for="show in listPrograms" :key="show.id" :value="show.id">{{show.code}} - {{show.name}}</option>
              </select>
              <label for="name">{{$t("modal.spot_programming.spot.detailedSchedule.show")}}</label>
            </div>
          </div>
          <div class="component-modal__column--third">
            <div class="component-modal-input component-modal-input--rename">
              <select name="" id="" v-model="model.businessUnitId">
                <option :value="null">{{$t("modal.placeholder_select")}}{{$t("modal.spot_programming.spot.detailedSchedule.unit")}}</option>
                <option v-for="business in listBusinessUnit" :key="business.id" :value="business.id">{{business.code}} - {{business.description}}</option>
              </select>
              <label for="name">{{$t("modal.spot_programming.spot.detailedSchedule.unit")}}</label>
            </div>
          </div>

          <div class="component-modal__column--third">
            <div class="component-modal-input component-modal-input--rename">
              <input type="number" name="name" id="name" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.spot_programming.spot.detailedSchedule.monthlyFee')" v-model="model.businessMonthlyFee">
              <label for="name">{{$t("modal.spot_programming.spot.detailedSchedule.monthlyFee")}}</label>
            </div>
          </div>
        </div>
      </div>

      <button type="button" @click.stop.prevent="saveAndClose">
        {{ props ? $t("modal.save") : $t('modal.create') }}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{ $t("modal.cancel") }}
      </button>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { minValue, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import eventBus from '@/plugins/eventBus';
import moment from 'moment';

export default {
  setup() {
		const v$ = useVuelidate();
    const store = useStore();
    const props = computed(() => store.getters.modal_getProps);
    const clientCategories = computed(() => store.getters.clientCategory_categories({code: '', name: ''}));
    const listBusinessUnit = computed(() => store.getters.businessUnit_businessUnit({code: '', description: ''}));
    const listPrograms = computed(() => store.getters.show_programs({code: '', name: ''}));
    const listspeakers = computed(() => store.getters.speaker_speaker({code: '', name: ''}));
    const calculateTime = computed(() => time => moment().startOf('day').seconds(time).format('mm:ss'))
    const formType = ref(1);
    const invalidDates = ref(false);
    const submitted = ref(false);
    const model = ref({
      id: 0,
      startTime: '00:00',
      endTime: '00:00',
      occurrences: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      timeFee: 0,
      commercialBreakSpotPosition: 6,
      fileName: '',
      length: 0,
      spotType: 0,
      ntcText: '',
      showId: null,
      businessUnitId: null,
      speakerId: null,
      idBusinessShow: null,
      businessMonthlyFee: 0,
      groupId: null
    });

    onMounted(async () => {
      model.value['internalReferenceSpot'] = props.value.internalReferenceSpot;
      if (props.value.monthSelect) model.value.date = moment(props.value.monthSelect, 'YYYY-MM').format("YYYY-MM-DD")
      if (props.value.edit) model.value = Object.assign({}, props.value);
      
      store.dispatch('clientCategory_getAll');
      store.dispatch('businessUnit_getAll');
      store.dispatch('show_getAll');
      store.dispatch('speaker_getAll');
    })

    async function closeModal() {
      await store.dispatch('modal_setActive');
      submitted.value = false;
    }

    async function saveAndClose() {
      submitted.value = true;
      // Validate custom
      const validateText = () => {
        if (model.value.spotType != 0) {
          return model.value.ntcText != '';
        }
        return true;
      }
      validateTimes();
      if (!v$.value.model.$invalid && !invalidDates.value && validateText()) {
        eventBus.$emit(props.value.edit ? 'editAdvancedSchedule' : 'addAdvancedSchedule', model.value);  
        this.closeModal();
      }
    }

    function validateTimes() {
      function _getMoment(time) {
        const [start, end] = time.split(":");
        return moment().hour(start).minutes(end);
      }

      if (model.value.startTime != '' && model.value.endTime != '') {
        // Validar que start sea distinto a end
        if (model.value.startTime == model.value.endTime) {
          invalidDates.value = true;
          return;
        }
        // Validar que start sea previo a end
        const startTimeValidate = _getMoment(model.value.startTime);
        const endTimeValidate = _getMoment(model.value.endTime);
        invalidDates.value = moment(startTimeValidate).isAfter(endTimeValidate);
      } else { 
        invalidDates.value = false;
      }
    }
  
    return {
      v$,
      props,
      model,
      clientCategories,
      formType,
      listBusinessUnit,
      listPrograms,
      listspeakers,
      calculateTime,
      submitted,
      invalidDates,
      saveAndClose,
      closeModal,
      validateTimes
    }
  },
  validations () {
    return {
      model: {
        fileName: {required},
        length: {required, minValueValue: minValue(1)},
        startTime: {required},
        endTime: {required}
			}
    }
  }
};
</script>
<style lang="scss" scoped>
  label {
    overflow: hidden;
  }
  .time {
    position: absolute;
    top: 0px;
    color: black;
    background: gray;
    padding: 2px 8px;
    border-radius: 12px;
    margin-left: 4px;
    font-size: 12px;
  }
</style>