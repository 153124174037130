<template>
  <div class="component-modal" id="modal">
    <form class="form" v-on:submit.prevent>
      <legend>{{$t("modal.user.change_language.title")}}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>
      
      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <select name="device" v-model="langSelect">
            <option value="EN">{{$t("settings.change_language.en")}}</option>
            <option value="ES">{{$t("settings.change_language.es")}}</option>
          </select>
        </div>
      </div>
      <button type="button" @click.stop.prevent="saveAndClose">
        {{ $t('modal.apply') }}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{ $t("modal.cancel") }}
      </button>
    </form>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import PersistentData from '@/plugins/persistentData';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';

export default {
  setup() {
    const langSelect = ref('ES');
    const store = useStore();

    onMounted(() => {
      langSelect.value = PersistentData.get('lang') || 'ES';
    })

    async function closeModal() {
      await store.dispatch('modal_setActive');
    }

    function saveAndClose() {
			PersistentData.set('lang', langSelect.value);
			window.i18n.global.locale.value = langSelect.value;
      this.closeModal();
    }

    return {
      langSelect,
      saveAndClose,
      closeModal
    }
  }
};
</script>
