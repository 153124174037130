import axios from 'axios';
import moment from 'moment';

import i18n from '@/plugins/i18n'
import persistentData from '@/plugins/persistentData';

const instance = axios.create({
  timeout: 300000
});

function getHeaders(isAnon=false) {
  let params = {
    'LANG': i18n.global.locale.value.toUpperCase(),
    'Content-Type': "application/json",
  };
  if(!isAnon && persistentData.get('token', false)) {
    params.Authorization = `Bearer ${persistentData.get('token', false)}`
    params.MultimediaId = window.vueApp.$store.getters.manageUser_multimedia && window.vueApp.$store.getters.manageUser_multimedia.id || ''
  }
  return params
}

const interceptor = {
  anonymous: {
    get: (url) => { 
      return instance.get(url,{ headers: getHeaders(true) }) 
    },
    post: (url, params) => {
      return instance.post(url, params,{ headers: getHeaders(true) })
    }
  },
  authenticate: {
    refreshToken: () => {
      return new Promise((resolve, reject) => {
        instance.post(`${process.env.VUE_APP_API_URL}/Authentication/refreshToken`, {'value': persistentData.get('token', false)}, { headers: getHeaders() })
          .then(res => {
            const expired = moment().add(7, 'm').format()
            persistentData.set('token', res.data.token, false)
            persistentData.set('tokenExpiration', expired, false)
            resolve(res)
          })
          .catch(err => {
            persistentData.remove('token');
            persistentData.remove('tokenExpiration');
            window.location = '/login';
            reject(err)
          })
      })
    },
    validateToken:() => {
      const self = interceptor.authenticate
      return new Promise((resolve) => {
        const expired = moment(persistentData.get('tokenExpiration', false))
        if (expired.diff(moment(), 'm') < 1) {
          self.refreshToken()
            .then(() => {
              resolve()
            })
        } else {
          resolve()
        }
      })
    },
    get: (url) => {
      const self = interceptor.authenticate
      return new Promise((resolve, reject) => {
        self.validateToken()
          .then(() => {
            instance.get(url, { headers: getHeaders() })
              .then(res => resolve(res))
              .catch(err => {
                console.log(err)
                if (err.response.status === 401) {
                  // Refresk Token
                  self.refreshToken()
                    .then(res_token => {
                      persistentData.set('token', res_token.data.token, false)
                      instance.get(url, { headers: getHeaders() })
                        .then(res => resolve(res))
                        .catch(err => {
                          reject(err)
                        })
                    })
                }
                reject(err)
              })
          })
      })
    },
    post: (url, params) => {
      const self = interceptor.authenticate
      return new Promise((resolve, reject) => {
        self.validateToken()
          .then(() => {
            instance.post(url, params, { headers: getHeaders() })
              .then(res => resolve(res))
              .catch(err => {
                console.log(err)
                if (err.response.status === 401) {
                  // Refresk Token
                  self.refreshToken()
                    .then(res_token => {
                      persistentData.set('token', res_token.data.token, false)
                      instance.post(url, params, { headers: getHeaders() })
                        .then(res => resolve(res))
                        .catch(err => {
                          reject(err)
                        })
                    })
                }
                reject(err)
              })
          })
      })
    },
    delete: (url, params) => {
      const self = interceptor.authenticate
      return new Promise((resolve, reject) => {
        self.validateToken()
          .then(() => {
            console.log(params);
            instance.delete(url, { headers: getHeaders() },params )
              .then(res => resolve(res))
              .catch(err => {
                console.log(err)
                if (err.response.status === 401) {
                  // Refresk Token
                  self.refreshToken()
                    .then(res_token => {
                      persistentData.set('token', res_token.data.token, false)
                      instance.delete(url, { headers: getHeaders() },params )
                        .then(res => resolve(res))
                        .catch(err => {
                          reject(err)
                        })
                    })
                }
                reject(err)
              })
          })
      })
    },
    put: (url, params) => {
      const self = interceptor.authenticate
      return new Promise((resolve, reject) => {
        self.validateToken()
          .then(() => {
            instance.put(url, params, { headers: getHeaders() })
              .then(res => resolve(res))
              .catch(err => {
                console.log(err)
                if (err.response.status === 401) {
                  // Refresk Token
                  self.refreshToken()
                    .then(res_token => {
                      persistentData.set('token', res_token.data.token, false)
                      instance.put(url, params, { headers: getHeaders() })
                        .then(res => resolve(res))
                        .catch(err => {
                          reject(err)
                        })
                    })
                }
                reject(err)
              })
          })
      })
    }
  },
  
};

export default interceptor;