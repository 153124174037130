import interceptor from '@/plugins/interceptor';
const pathController = '/Show'

export default {
  state: () => ({
    programs: []
  }),
  getters: {
    show_programs: state => filter => state.programs.filter(program => {
      if (filter.name && filter.code) return program.name.toLowerCase().includes(filter.name.toLowerCase()) && program.code.toLowerCase().includes(filter.code.toLowerCase())
      if (filter.name) return program.name.toLowerCase().includes(filter.name.toLowerCase())
      if (filter.code) return program.code.toLowerCase().includes(filter.code.toLowerCase())
      return true;
    }),
    show_programById: state => id => {
      const item = state.programs.find(program => program.id == id);
      return item ? `${item.code} - ${item.name}` : '-';
    }
  },
  mutations: { 
    show_setPrograms(state, value) {
      state.programs = value;
    },
    show_updatePrograms(state, model) {
      const idx = state.programs.findIndex(p => p.id == model.id);
      state.programs[idx] = model;
    },
    show_addPrograms(state, model) {
      state.programs.push(model);
    },
    show_deletePrograms(state, id) {
      const idx = state.programs.findIndex(p => p.id == id);
      state.programs.splice(idx, 1);
    }
  },
  actions: {
    async show_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('show_setPrograms', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.station_config.show'), error: true});
        throw new Error(error);
      }
    },
    async show_create({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        commit('show_addPrograms', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.station_config.show')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.station_config.show'), error: true});
        throw new Error(error);
      }
    },
    async show_update({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        commit('show_updatePrograms', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.station_config.show')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.station_config.show'), error: true});
        throw new Error(error);
      }
    }, 
    async show_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?showId=${model.id}`);
        commit('show_deletePrograms', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.station_config.show')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.station_config.show'), error: true});
        throw new Error(error);
      }
    },
  }
}

