import interceptor from '@/plugins/interceptor';
const pathController = '/Speaker'

export default {
  state: () => ({
    speaker: []
  }),
  getters: {
    speaker_speaker: state => filter => state.speaker.filter(speaker => {
      if (filter.name && filter.code) return speaker.name.toLowerCase().includes(filter.name.toLowerCase()) && speaker.code.toLowerCase().includes(filter.code.toLowerCase())
      if (filter.name) return speaker.name.toLowerCase().includes(filter.name.toLowerCase())
      if (filter.code) return speaker.code.toLowerCase().includes(filter.code.toLowerCase())
      return true;
    }),
  },
  mutations: { 
    speaker_setspeaker(state, value) {
      state.speaker = value;
    },
    speaker_updatespeaker(state, model) {
      const idx = state.speaker.findIndex(p => p.id == model.id);
      state.speaker[idx] = model;
    },
    speaker_addspeaker(state, model) {
      state.speaker.push(model);
    },
    speaker_deletespeaker(state, id) {
      const idx = state.speaker.findIndex(p => p.id == id);
      state.speaker.splice(idx, 1);
    }
  },
  actions: {
    async speaker_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('speaker_setspeaker', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.station_config.speaker'), error: true});
        throw new Error(error);
      }
    },
    async speaker_create({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        commit('speaker_addspeaker', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.station_config.speaker')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.station_config.speaker'), error: true});
        throw new Error(error);
      }
    },
    async speaker_update({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        commit('speaker_updatespeaker', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.station_config.speaker')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.station_config.speaker'), error: true});
        throw new Error(error);
      }
    }, 
    async speaker_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?speakerId=${model.id}`);
        commit('speaker_deletespeaker', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.station_config.speaker')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.station_config.speaker'), error: true});
        throw new Error(error);
      }
    },
  }
}

