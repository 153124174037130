<template>
  <RouterView />
  <Notification />
</template>
<script>
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import Notification from '@/components/Common/Notifications/Main.vue';
import PersistentData from '@/plugins/persistentData';
import { useRoute } from 'vue-router';

export default {
  components: {
    Notification
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    if (route.name != "CustomerFacing") store.dispatch('manageUser_active');

    onMounted(async () => {
      try {
        const requestVersion = await store.dispatch("common_version");
        const versionStorage = PersistentData.get('web-v');
  
        if (versionStorage && versionStorage == requestVersion.data) return;
        PersistentData.set('web-v', requestVersion.data);
        location.reload(true);
      } catch (error) {
        console.log("Error get version ", error);
      }
    })
  }
}
</script>
<style lang="scss">
  @import '@/styles/_common.scss';
</style>