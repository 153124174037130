<template>
  <div class="component-modal" id="modal">
    <form class="form" v-on:submit.prevent>
      <legend>{{props ? `${$t("modal.config_users.edit")} ${props.name}` : $t("modal.config_users.create")}}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>
      
      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="text" name="name" id="name" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.config_users.name')" v-model="model.name">
          <label for="name">{{$t("modal.config_users.name")}}</label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="text" name="email" id="email" autocomplete="off" :placeholder="$t('modal.placeholder_insert') + $t('modal.config_users.email')" v-model="model.email">
          <label for="email">{{$t("modal.config_users.email")}}</label>
        </div>
      </div>
    
      <button type="button" @click.stop.prevent="saveAndClose">
        {{ props ? $t("modal.save") : $t('modal.invite') }}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{ $t("modal.cancel") }}
      </button>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const props = computed(() => store.getters.modal_getProps);
    const model = ref({
      name: '',
      email: ''
    })

    onMounted(() => {
      if (props.value) model.value = props.value;
    })

    async function closeModal() {
      await store.dispatch('modal_setActive');
    }

    function saveAndClose() {
      this.closeModal();
    }

    return {
      props,
      model,
      saveAndClose,
      closeModal
    }
  }
};
</script>