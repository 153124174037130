<template>
  <div v-if="active != ''">
    <CloseTab v-if="active == 'COMMON_CLOSE_TAB'" />
    <CommonDelete v-if="active == 'COMMON_DELETE'" />
    <ChangeLanguage v-if="active == 'CHANGE_LANGUAGE'" />
    <!-- RadioStation Config -->
    <StationConfigCommercialBlockCreateEdit v-if="active == 'STATION-CONFIG_SHEDULES_COMMERCIAL-BLOCK-CREATE-EDIT'" />
    <StationConfigShowsCreateEdit v-if="active == 'STATION-CONFIG_SHEDULES_SHOWS-CREATE-EDIT'" />
    <StationConfigCopyDay v-if="active == 'STATION-CONFIG_SCHEDULES_COPY-DAY'" />
    <StationConfigCopyStation v-if="active == 'STATION-CONFIG_SCHEDULES_COPY-STATION'" />
    <StationConfigSpeakerCreateEdit v-if="active == 'STATION-CONFIG_SPEAKER_CREATE-EDIT'" />
    <StationConfigShowCreateEdit v-if="active == 'STATION-CONFIG_SHOW_CREATE-EDIT'" />
    <!-- Commercial Config -->
    <CommercialConfigClientCategoryCreateEdit v-if="active == 'COMMERCIAL-CONFIG_CLIENT-CATEGORY_CREATE-EDIT'" />
    <CommercialConfigAdAgencyCreateEdit v-if="active == 'COMMERCIAL-CONFIG_AD-AGENCY_CREATE-EDIT'" />
    <CommercialConfigSpotCategoryCreateEdit v-if="active == 'COMMERCIAL-CONFIG_SPOT-CATEGORY_CREATE-EDIT'" />
    <CommercialConfigAdAgencyGroupCreateEdit v-if="active == 'COMMERCIAL-CONFIG_AD-AGENCY-GROUP_CREATE-EDIT'" />
    <CommercialConfigAccountExecutiveCreateEdit v-if="active == 'COMMERCIAL-CONFIG_ACCOUNT-EXECUTIVE_CREATE-EDIT'" />
    <CommercialConfigPaymentMethodCreateEdit v-if="active == 'COMMERCIAL-CONFIG_PAYMENT-METHOD_CREATE-EDIT'" />
    <CommercialConfigFromCreateEdit v-if="active == 'COMMERCIAL-CONFIG_ORIGIN_CREATE-EDIT'" />
    <CommercialConfigBusinessUnitCreateEdit v-if="active == 'COMMERCIAL-CONFIG_BUSINESS-UNIT_CREATE-EDIT'" />
    <!-- Spot Programming -->
    <SpotProgrammingClientCreateEdit v-if="active == 'SPOT-PROGRAMMING_CLIENT_CREATE-EDIT'" />
    <SpotProgrammingSpotSimpleSchedule v-if="active == 'SPOT-PROGRAMMING_SPOT_SIMPLE-SCHEDULE_CREATE-EDIT'" />
    <SpotProgrammingSpotDetailedSchedule v-if="active == 'SPOT-PROGRAMMING_SPOT_DETAILED-SHEDULE_CREATE-EDIT'" />
    <SpotProgrammingSpotSaveTest v-if="active == 'SPOT-PROGRAMMING_SPOT_SAVETEST'" />
    <!-- Config Users -->
    <ConfigUsersCreateEdit v-if="active == 'CONFIG_USERS_CREATE-EDIT'" />
    <div class="component-modal-overlay" @click="closeModal()"></div>
  </div>
</template>
<script>
import CloseTab from '@/components/Modal/Common/CloseTab';
import CommonDelete from '@/components/Modal/Common/Delete';
import ChangeLanguage from '@/components/Modal/User/ChangeLanguage';

import StationConfigCommercialBlockCreateEdit from '@/components/Modal/StationConfig/Schedules/CommercialBlockCreateEdit';
import StationConfigShowsCreateEdit from '@/components/Modal/StationConfig/Schedules/ShowsCreateEdit';
import StationConfigCopyDay from '@/components/Modal/StationConfig/Schedules/CopyDay';
import StationConfigCopyStation from '@/components/Modal/StationConfig/Schedules/CopyStation';
import StationConfigSpeakerCreateEdit from '@/components/Modal/StationConfig/Speaker/CreateEdit';
import StationConfigShowCreateEdit from '@/components/Modal/StationConfig/Show/CreateEdit';

import CommercialConfigClientCategoryCreateEdit from '@/components/Modal/CommercialConfig/ClientCategory/CreateEdit';
import CommercialConfigAdAgencyCreateEdit from '@/components/Modal/CommercialConfig/AdAgency/CreateEdit';
import CommercialConfigSpotCategoryCreateEdit from '@/components/Modal/CommercialConfig/SpotCategory/CreateEdit';
import CommercialConfigAdAgencyGroupCreateEdit from '@/components/Modal/CommercialConfig/AdAgencyGroup/CreateEdit';
import CommercialConfigAccountExecutiveCreateEdit from '@/components/Modal/CommercialConfig/AccountExecutive/CreateEdit';
import CommercialConfigPaymentMethodCreateEdit from '@/components/Modal/CommercialConfig/PaymentMethod/CreateEdit';
import CommercialConfigFromCreateEdit from '@/components/Modal/CommercialConfig/From/CreateEdit';
import CommercialConfigBusinessUnitCreateEdit from '@/components/Modal/CommercialConfig/BusinessUnit/CreateEdit';

import SpotProgrammingClientCreateEdit from '@/components/Modal/SpotProgramming/Client/CreateEdit';
import SpotProgrammingSpotSimpleSchedule from '@/components/Modal/SpotProgramming/Spot/SimpleSchedule/CreateEdit';
import SpotProgrammingSpotDetailedSchedule from '@/components/Modal/SpotProgramming/Spot/DetailedSchedule/CreateEdit';
import SpotProgrammingSpotSaveTest from '@/components/Modal/SpotProgramming/Spot/SaveTest';

import ConfigUsersCreateEdit from '@/components/Modal/ConfigUser/CreateEdit';

import { useStore } from 'vuex';
import { computed } from '@vue/runtime-core';

export default {
  components: {
    CloseTab,
    CommonDelete,
    ChangeLanguage,
    StationConfigCommercialBlockCreateEdit,
    StationConfigShowsCreateEdit,
    StationConfigCopyDay,
    StationConfigCopyStation,
    StationConfigSpeakerCreateEdit,
    StationConfigShowCreateEdit,
    CommercialConfigClientCategoryCreateEdit,
    CommercialConfigAdAgencyCreateEdit,
    CommercialConfigSpotCategoryCreateEdit,
    CommercialConfigAdAgencyGroupCreateEdit,
    CommercialConfigAccountExecutiveCreateEdit,
    CommercialConfigPaymentMethodCreateEdit,
    CommercialConfigFromCreateEdit,
    CommercialConfigBusinessUnitCreateEdit,
    SpotProgrammingClientCreateEdit,
    SpotProgrammingSpotSimpleSchedule,
    SpotProgrammingSpotDetailedSchedule,
    SpotProgrammingSpotSaveTest,
    ConfigUsersCreateEdit
  },
  setup() {
    const store = useStore();
    const active = computed(() => store.getters.modal_getActive);
    const props = computed(() => store.getters.modal_getProps);

    async function closeModal() {
      await store.dispatch('modal_setActive');
    }

    return {
      active,
      props,
      closeModal
    }
  }
}
</script>
<style lang="scss">
  @import '@/styles/_modal.scss';
</style>