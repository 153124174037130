export default {
  state: () => ({
    active: false
  }),
  getters: {
    loading_getActive: state => state.active,    
  },
  mutations: {
    loading_setActive(state, value) {
      state.active = value;
    }
  },
  actions: {
    loading_setActive({commit}, value) {
      commit('loading_setActive', value);
    }
  }
}