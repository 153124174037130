import interceptor from '@/plugins/interceptor';
const pathController = '/TrafficLog'

export default {
  state: () => ({
  }),
  getters: {
  },
  mutations: { 
  },
  actions: {
    async trafficLog_generate({ dispatch }, model) {
      try {
        await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/generate`, model);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.spot_programming.spot')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.spot_programming.spot'), error: true});
        throw new Error(error);
      }
    }
  }
}

