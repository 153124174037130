import interceptor from '@/plugins/interceptor';
const pathController = '/Station'

export default {
  state: () => ({
    stations: [],
  }),
  getters: {
    station_stations: state => filter => state.stations.filter(station => filter.name ? station.name.toLowerCase().includes(filter.name.toLowerCase()) : true)
  },
  mutations: { 
    station_setStations(state, value) {
      state.stations = value;
    },
  },
  actions: {
    async station_getAll({commit, dispatch}) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('station_setStations', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.station_config.station'), error: true});
        throw new Error(error);
      }
    },
  }
}

