export default {
  state: () => ({
    tabs: [],
    active: {}
  }),
  getters: {
    tabs_getTabs: state => state.tabs,    
    tabs_getActive: state => state.active,
    tabs_getActiveExtendedData: state => state.active ? state.active.extendedData : {} 
  },
  mutations: {
    tabs_addTab(state, tab) {
      const tabExist = state.tabs.find(t => t.id == tab.id);
      if (tabExist) {
        state.active = tabExist;
      } else {
        state.tabs.push(tab);
      }
    },
    tabs_removeTab(state, tab) {
      const index = state.tabs.findIndex(t => t.id == tab.id);
      state.tabs.splice(index, 1);
    },
    tabs_setActive(state, tab) {
      state.active = tab;
    },
    tabs_selectFirstTab(state) {
      state.active = state.tabs[0];
    },
    tabs_updateTab(state, tab) {
      const index = state.tabs.findIndex(t => t.id == tab.id);
      state.tabs[index].extendedData = tab.extendedData;
    },
    tabs_setPropChanges(state, tab) {
      const index = state.tabs.findIndex(t => t.id == tab.id);
      state.tabs[index].extendedData = {...state.tabs[index].extendedData, changes: tab.changes};
    }
  },
  actions: {
    tabs_addTab({commit}, tab) {
      commit('tabs_addTab', tab);
    },
    tabs_removeTab({commit}, tab) {
      commit('tabs_removeTab', tab);
    },
    tabs_setActive({commit}, tab) {
      commit('tabs_setActive', tab);
    },
    tabs_selectFirstTab({commit}) {
      commit('tabs_selectFirstTab');
    },
    tabs_updateTab({commit}, tab) {
      commit('tabs_updateTab', tab);
    },
    tabs_setPropChanges({commit}, tab) {
      commit('tabs_setPropChanges', tab);
    }
  }
}