<template>
  <div class="component-modal" id="modal">
    <form class="form" v-on:submit.prevent>
      <legend>{{ $t("modal.station_config.schedules.copy_station") }}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>

      <div class="component-modal__item-detail">
        <ul>
          <template v-for="key in Object.keys(modelProps)" :key="key">
            <li v-if="key != 'id'">
              <strong>{{ $t(`modal.common.delete.props.${key}`) }}</strong> : {{getStation(props[key])}}
            </li>  
          </template>
        </ul> 
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <select name="station" id="station" v-model="model.stationIdTo">
            <option :value="0">{{$t("modal.placeholder_select")}}{{$t("modal.station_config.schedules.station_to")}}</option>
            <option v-for="station in listStations" :value="station.id" :key="station.id" :disabled="station.id == model.stationId">{{station.name}}</option>
          </select>
          <label for="code">{{$t("modal.station_config.schedules.station_to")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row">
        <label>{{ $t("modal.station_config.schedules.items_to_copy") }} <small>*</small></label>
        <div class="component-modal-checkbox">
          <label>
            <input type="checkbox" v-model="model.copyCommercialBreaks">
            {{ $t("modal.station_config.schedules.schedules") }}  
          </label>
        </div>
        <div class="component-modal-checkbox">
          <label for="allow">
            <input type="checkbox" v-model="model.copyShows">
            {{ $t("modal.station_config.schedules.program") }}  
          </label>
        </div>
      </div>

      <!-- Alert validation -->
      <div class="alert warning" v-if="invalidForm">
        <i class="material-icons">info_outline</i>
        {{$t("modal.station_config.schedules.checkFields")}}
      </div>
    
      <button type="button" @click.stop.prevent="saveAndClose">
        {{ props ? $t("modal.save") : $t('modal.create') }}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{ $t("modal.cancel") }}
      </button>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const props = computed(() => store.getters.modal_getProps);
    const listStations = computed(() => store.getters.station_stations({name: ''}));
    const modelProps = ref({});
    const invalidForm = ref(false);
    const model = ref({
      stationId: 0,
      stationIdTo: 0,
      copyCommercialBreaks: true,
      copyShows: true
    });
    
    onMounted(() => {
      if (props.value) {
        modelProps.value = Object.assign({}, props.value);
        model.value.stationId = modelProps.value.stationId;
      }
    })

    async function closeModal() {
      await store.dispatch('modal_setActive');
      invalidForm.value = false;
    }

    async function saveAndClose() {
      if (model.value.stationIdTo != 0 && (model.value.copyCommercialBreaks || model.value.copyShows)) {
        try {
          await store.dispatch('schedule_copyStation', model.value);
          this.closeModal();
        } catch (error) {
          console.log(error);
        }
      } else {
        invalidForm.value = true;
      }
    }

    function getStation(id) {
      const listStations = store.getters.station_stations({code: '', name: ''});
      const station = listStations.find(station => station.id == id);
      return station.name;
    }

    return {
      props,
      modelProps,
      listStations,
      model,
      invalidForm,
      saveAndClose,
      closeModal,
      getStation
    }
  }
};
</script>