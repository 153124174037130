<template>
  <div class="left-menu">
    <!-- Options -->
    <div class="left-menu__options">
      <ul>
        <div>
          <li class="left-menu__options__option">
            <button @click="layoutActive = layoutActive == 'RADIO STATION CONFIG' ? '' : 'RADIO STATION CONFIG'">
              <i class="material-icons">settings_input_antenna</i>
              <span>{{ $t("left_menu.station_config.title") }}</span>
            </button>
          </li>
          <li class="left-menu__options__option">
            <button @click="layoutActive = layoutActive == 'COMMERCIAL CONFIG' ? '' : 'COMMERCIAL CONFIG'">
              <i class="material-icons">manage_accounts</i>
              <span>{{ $t("left_menu.commercial_config.title") }}</span>
            </button>
          </li>
          <li class="left-menu__options__option">
            <button @click="layoutActive = layoutActive == 'CAMPAIGN CONFIG' ? '' : 'CAMPAIGN CONFIG'">
              <i class="material-icons">campaign</i>
              <span>{{ $t("left_menu.spot_programming.title") }}</span>
            </button>
          </li>
          <li class="left-menu__options__option disabled">
            <button disabled @click="layoutActive = layoutActive == 'REPORT' ? '' : 'REPORT'">
              <i class="material-icons">summarize</i>
              <span>{{ $t("left_menu.report.title") }}</span>
            </button>
          </li>
          <li class="left-menu__options__option disabled">
            <button disabled @click="layoutActive = layoutActive == 'SUPPORT' ? '' : 'SUPPORT'">
              <i class="material-icons">support_agent</i>
              <span>{{ $t("left_menu.support.title") }}</span>
            </button>
          </li>
        </div>
        <div>
          <li class="left-menu__options__option disabled">
            <button disabled @click="addTab('config', 'users')">
              <i class="material-icons">settings</i>
              <span>{{ $t("left_menu.settings") }}</span>
            </button>
          </li>
        </div>
      </ul>
    </div>
    <!-- Menus -->
    <div class="left-menu__layout" :class="{ active: layoutActive == 'RADIO STATION CONFIG'}">
      <h2>{{$t('left_menu.station_config.title')}}</h2>
      <ul>
        <li>
          <div class="left-menu__layout__button" @click="addTab('station_config', 'station')">
            <button :title="$t('left_menu.station_config.station')">{{$t('left_menu.station_config.station')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button" @click="addTab('station_config', 'schedules')">
            <button :title="$t('left_menu.station_config.schedules')">{{$t('left_menu.station_config.schedules')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button" @click="addTab('station_config', 'speaker')">
            <button :title="$t('left_menu.station_config.speaker')">{{$t('left_menu.station_config.speaker')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button" @click="addTab('station_config', 'show')">
            <button :title="$t('left_menu.station_config.show')">{{$t('left_menu.station_config.show')}}</button>
          </div>
        </li>
      </ul>
    </div>

    <div class="left-menu__layout" :class="{ active: layoutActive == 'COMMERCIAL CONFIG'}">
      <h2>{{$t('left_menu.commercial_config.title')}}</h2>
      <ul>
        <li>
          <div class="left-menu__layout__button" @click="addTab('commercial_config', 'client_category')">
            <button :title="$t('left_menu.commercial_config.client_category')">{{$t('left_menu.commercial_config.client_category')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button"  @click="addTab('commercial_config', 'spot_category')">
            <button :title="$t('left_menu.commercial_config.spot_category')">{{$t('left_menu.commercial_config.spot_category')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button"  @click="addTab('commercial_config', 'ad_agency_group')">
            <button :title="$t('left_menu.commercial_config.ad_agency_group')">{{$t('left_menu.commercial_config.ad_agency_group')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button"  @click="addTab('commercial_config', 'ad_agency')">
            <button :title="$t('left_menu.commercial_config.ad_agency')">{{$t('left_menu.commercial_config.ad_agency')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button"  @click="addTab('commercial_config', 'account_executive')">
            <button :title="$t('left_menu.commercial_config.account_executive')">{{$t('left_menu.commercial_config.account_executive')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button"  @click="addTab('commercial_config', 'pay_method')">
            <button :title="$t('left_menu.commercial_config.pay_method')">{{$t('left_menu.commercial_config.pay_method')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button"  @click="addTab('commercial_config', 'origin')">
            <button :title="$t('left_menu.commercial_config.origin')">{{$t('left_menu.commercial_config.origin')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button"  @click="addTab('commercial_config', 'business_unit')">
            <button :title="$t('left_menu.commercial_config.business_unit')">{{$t('left_menu.commercial_config.business_unit')}}</button>
          </div>
        </li>
      </ul>
    </div>

    <div class="left-menu__layout" :class="{ active: layoutActive == 'CAMPAIGN CONFIG'}">
      <h2>{{$t('left_menu.spot_programming.title')}}</h2>
      <ul>
        <li>
          <div class="left-menu__layout__button" @click="addTab('spot_programming', 'client')">
            <button :title="$t('left_menu.spot_programming.client')">{{$t('left_menu.spot_programming.client')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button" @click="addTab('spot_programming', 'spot')">
            <button :title="$t('left_menu.spot_programming.spot')">{{$t('left_menu.spot_programming.spot')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button" @click="openModal('SPOT-PROGRAMMING_SPOT_SAVETEST')">
            <button :title="$t('left_menu.spot_programming.generate_block')">{{$t('left_menu.spot_programming.generate_block')}}</button>
          </div>
        </li>
        <li class="disabled">
          <!-- <div class="left-menu__layout__button" @click="addTab('spot_programming', 'certificate')"> -->
          <div class="left-menu__layout__button">
            <button :title="$t('left_menu.spot_programming.certificate')">{{$t('left_menu.spot_programming.certificate')}}</button>
          </div>
        </li>
        <li class="disabled">
          <!-- <div class="left-menu__layout__button" @click="addTab('spot_programming', 'import')"> -->
          <div class="left-menu__layout__button">
            <button :title="$t('left_menu.spot_programming.import')">{{$t('left_menu.spot_programming.import')}}</button>
          </div>
        </li>
      </ul>
    </div>

    <div class="left-menu__layout" :class="{ active: layoutActive == 'REPORT'}">
      <h2>{{$t('left_menu.report.title')}}</h2>
      <ul>
        <li>
          <div class="left-menu__layout__button" @click="addTab('report', 'agency')">
            <button :title="$t('left_menu.report.agency')">{{$t('left_menu.report.agency')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button"  @click="addTab('report', 'pnt')">
            <button :title="$t('left_menu.report.pnt')">{{$t('left_menu.report.pnt')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button"  @click="addTab('report', 'profile')">
            <button :title="$t('left_menu.report.profile')">{{$t('left_menu.report.profile')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button"  @click="addTab('report', 'campaign')">
            <button :title="$t('left_menu.report.campaign')">{{$t('left_menu.report.campaign')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button"  @click="addTab('report', 'sales')">
            <button :title="$t('left_menu.report.sales')">{{$t('left_menu.report.sales')}}</button>
          </div>
        </li>
      </ul>
    </div>

    <div class="left-menu__layout" :class="{ active: layoutActive == 'SUPPORT'}">
      <h2>{{$t('left_menu.support.title')}}</h2>
      <ul>
        <li>
          <div class="left-menu__layout__button" @click="addTab('support', 'news')">
            <button :title="$t('left_menu.support.news')">{{$t('left_menu.support.news')}}</button>
          </div>
        </li>
        <li>
          <div class="left-menu__layout__button" @click="addTab('support', 'contact')">
            <button :title="$t('left_menu.support.contact')">{{$t('left_menu.support.contact')}}</button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { onMounted } from '@vue/runtime-core';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const layoutActive = ref("");

    async function addTab(module, name) {
      const tab = {
        id: new Date().getUTCMilliseconds(),
        module,
        name,
        extendedData: {}
      }
      await store.dispatch('tabs_addTab', tab);
      await store.dispatch('tabs_setActive', tab);
      router.push(`/${module}/${name}/${tab.id}`);
      layoutActive.value = '';
    }

    async function openModal(modal) {
      await store.dispatch('modal_setActive', {active: modal});
      layoutActive.value = '';
    }

    onMounted(async () => {
      if (route.name == 'Workspace') {
        addTab('station_config', 'station');
      } else {
        let tab = {}
        if (route.name == "SPOT_DETAIL") {
          tab = {
            id: route.params.id,
            module: 'spot_programming',
            name: 'spot/detail',
            extendedData: {}
          }
        } else {
          const params = route.path.split('/');
          tab = {
            id: params[3],
            module: params[1],
            name: params[2],
            extendedData: {}
          }
        }
        await store.dispatch('tabs_addTab', tab);
        await store.dispatch('tabs_setActive', tab);
      }
    })

    return {
      layoutActive,
      openModal,
      addTab
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.left-menu {
  &__options {
    align-items: flex-start;
    display: flex;
    height: calc(100vh - 32px);
    justify-content: center;
    padding: 16px;
    border: 4px solid #7763ff;
    border-radius: 16px;
    margin: 16px 0 16px 16px;
    // position: sticky;
    // top: 16px;
    // z-index: 1;
    width: 64px;
    grid-area: left-menu;
    z-index: 10;
    
    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      z-index: 9;
      overflow: visible;
      @include vertical-scrollbar;
  
      &.active {
        background: $darker-color;
      }
  
      li {
        height: 72px;
        text-align: center;
        padding: 0 8px;
  
        button {
          align-items: center;
          background: none;
          border: none;
          display: flex;
          justify-content: center;
          margin: 0;
          padding: 0;
          position: relative;
          width: 100%;
          color: white;
  
          span {
            background: white;
            border-radius: 20px;
            color: black;
            font-size: 0px;
            font-weight: 700;
            left: 36px;
            min-height: 16px;
            min-width: 16px;
            overflow: hidden;
            // padding: 2px 6px;
            position: absolute;
            scale: .9;
            text-transform: uppercase;
            top: 28px;
            transition: .3s ease all;
            visibility: hidden;
            white-space: nowrap;
          }
  
          i {
            font-size: 32px;
            line-height: 72px;
            height: 72px;
            width: 100%;
            display: block;
            cursor: pointer;
  
            &:hover {
              color: rgba(255, 255, 255, 0.5);
              transition: .3s ease;
            }
          }
        }
  
        &.active {
          background: $darker-color;
  
          a {
            border-bottom: none;
          }
  
          // .icon-search,
          // .icon-view_quilt,
          // .icon-radio,
          // .icon-settings {
          //   color: $secondary-color;
          // }
        }
  
        &:hover {
          button {
            opacity: 1;
            
            span {
              font-size: 11px;
              scale: 1;
              visibility: visible;
              z-index: 3;
              padding: 2px 6px;
            }
          }
  
          i {
            scale: 1.05;
            transition: .3s ease all;
          }
        }
      }
    }
  }

  &__layout {
    width: 242px;
    // background: $darker-color;
    border: 4px solid #7763ff;
    height: calc(100vh - 32px);
    padding: 24px;
    margin: 16px;
    border-radius: 16px;
    position: absolute;
    transition: .4s ease all;
    top: 0;
    transform: translateX(-320px);
    // background: linear-gradient(180deg,#505050,#101010);
    background: linear-gradient(180deg, #3c3280, #191538);
    opacity: 0;
    z-index: 2;
    
    h2 {
      font-family: "Roboto Condensed", sans-serif;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 8px;
      margin: 0;
      text-transform: uppercase;
      text-align: left;
    }

    &.active {
      opacity: 1;
      transform: translateX(76px);
    }

    ul {
      height: auto;
      overflow-y: auto;
      padding: 0;
      margin-bottom: 32px;
      margin-top: 8px;
    }

    li {
      width: 100%;
      display: table;
      margin-bottom: 8px;
      
      .left-menu__layout__button {
        max-width: 0;
        position: relative;
        display: table-cell;
        vertical-align: top;

        button {            
          font-family: $font-roboto-cond, sans-serif;
          font-size: 14px;
          color: $light-gray-color;
          background: $primary-color;
          padding: 8px 8px;
          text-align: center;
          text-transform: uppercase;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          &:hover, &:disabled {
            opacity: .5;
          }

          &.add {
            background: $secondary-color;
            color: $primary-color;
          }
        }
      }
    }
  }
}
</style>