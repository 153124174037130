import interceptor from '@/plugins/interceptor';
const pathController = '/ClientCategory'

export default {
  state: () => ({
    categories: []
  }),
  getters: {
    clientCategory_categories: state => filter => state.categories.filter(category => {
      if (filter.name && filter.code) return category.name.toLowerCase().includes(filter.name.toLowerCase()) && category.code.toLowerCase().includes(filter.code.toLowerCase())
      if (filter.name) return category.name.toLowerCase().includes(filter.name.toLowerCase())
      if (filter.code) return category.code.toLowerCase().includes(filter.code.toLowerCase())
      return true;
    }),
  },
  mutations: { 
    clientCategory_setCategories(state, value) {
      state.categories = value;
    },
    clientCategory_updateCategories(state, model) {
      const idx = state.categories.findIndex(p => p.id == model.id);
      state.categories[idx] = model;
    },
    clientCategory_addCategories(state, model) {
      state.categories.push(model);
    },
    clientCategory_deleteCategories(state, id) {
      const idx = state.categories.findIndex(p => p.id == id);
      state.categories.splice(idx, 1);
    }
  },
  actions: {
    async clientCategory_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('clientCategory_setCategories', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.commercial_config.client_category'), error: true});
        throw new Error(error);
      }
    },
    async clientCategory_create({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        commit('clientCategory_addCategories', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.commercial_config.client_category')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.client_category'), error: true});
        throw new Error(error);
      }
    },
    async clientCategory_update({ commit, dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        commit('clientCategory_updateCategories', request.data);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.commercial_config.client_category')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.client_category'), error: true});
        throw new Error(error);
      }
    }, 
    async clientCategory_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?clientCategoryId=${model.id}`);
        commit('clientCategory_deleteCategories', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.commercial_config.client_category')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.commercial_config.client_category'), error: true});
        throw new Error(error);
      }
    },
  }
}

