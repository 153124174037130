import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login/Main.vue'
import LoginExternal from '@/views/Login/External.vue'
import CustomerFacing from '@/views/CustomerFacing/Main.vue'
import Workspace from '@/views/Workspace/Main.vue'
import persistentData from '@/plugins/persistentData'

const guard = (to, from, next) => {
  persistentData.get('token') != null ? next() : next('/login');
};

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/loginExternal',
    name: 'LoginExternal',
    component: LoginExternal
  },
  {
    path: '/customerFacing',
    name: 'CustomerFacing',
    component: CustomerFacing
  },
  {
    path: '/',
    name: 'Workspace',
    component: Workspace,
    beforeEnter: guard,
    children: [
      // Radio Station Config
      {
        path: 'station_config/station/:id',
        component: () => import('@/views/Workspace/StationConfig/Station.vue')
      },
      {
        path: 'station_config/schedules/:id',
        component: () => import('@/views/Workspace/StationConfig/Schedules.vue')
      },
      {
        path: 'station_config/speaker/:id',
        component: () => import('@/views/Workspace/StationConfig/Speaker.vue')
      },
      {
        path: 'station_config/show/:id',
        component: () => import('@/views/Workspace/StationConfig/Show.vue')
      },
      // Commercial config
      {
        path: 'commercial_config/client_category/:id',
        component: () => import('@/views/Workspace/CommercialConfig/ClientCategory.vue')
      },
      {
        path: 'commercial_config/ad_agency/:id',
        component: () => import('@/views/Workspace/CommercialConfig/AdAgency.vue')
      },
      {
        path: 'commercial_config/spot_category/:id',
        component: () => import('@/views/Workspace/CommercialConfig/SpotCategory.vue')
      },
      {
        path: 'commercial_config/ad_agency_group/:id',
        component: () => import('@/views/Workspace/CommercialConfig/AdAgencyGroup.vue')
      },
      {
        path: 'commercial_config/account_executive/:id',
        component: () => import('@/views/Workspace/CommercialConfig/AccountExecutive.vue')
      },
      {
        path: 'commercial_config/pay_method/:id',
        component: () => import('@/views/Workspace/CommercialConfig/PayMethod.vue')
      },
      {
        path: 'commercial_config/origin/:id',
        component: () => import('@/views/Workspace/CommercialConfig/Origin.vue')
      },
      {
        path: 'commercial_config/business_unit/:id',
        component: () => import('@/views/Workspace/CommercialConfig/BusinessUnit.vue')
      },
      // Campaign Config
      {
        path: 'spot_programming/client/:id',
        component: () => import('@/views/Workspace/SpotProgramming/Client.vue')
      },
      {
        path: 'spot_programming/spot/:id',
        component: () => import('@/views/Workspace/SpotProgramming/Spot.vue')
      },
      {
        name: "SPOT_DETAIL",
        path: 'spot_programming/spot/detail/:id',
        component: () => import('@/views/Workspace/SpotProgramming/SpotDetail.vue')
      },
      {
        path: 'spot_programming/generate_block/:id',
        component: () => import('@/views/Workspace/SpotProgramming/GenerateBlock.vue')
      },
      {
        path: 'spot_programming/certificate/:id',
        component: () => import('@/views/Workspace/SpotProgramming/Certificate.vue')
      },
      {
        path: 'spot_programming/import/:id',
        component: () => import('@/views/Workspace/SpotProgramming/Import.vue')
      },
      // Report
      {
        path: 'report/agency/:id',
        component: () => import('@/views/Workspace/Report/Agency.vue')
      },
      {
        path: 'report/pnt/:id',
        component: () => import('@/views/Workspace/Report/PNT.vue')
      },
      {
        path: 'report/profile/:id',
        component: () => import('@/views/Workspace/Report/Profile.vue')
      },
      {
        path: 'report/campaign/:id',
        component: () => import('@/views/Workspace/Report/Campaign.vue')
      },
      {
        path: 'report/sales/:id',
        component: () => import('@/views/Workspace/Report/Sales.vue')
      },
      // Support
      {
        path: 'support/news/:id',
        component: () => import('@/views/Workspace/Support/News.vue')
      },
      {
        path: 'support/contact/:id',
        component: () => import('@/views/Workspace/Support/Contact.vue')
      },
      // Config
      {
        path: 'config/users/:id',
        component: () => import('@/views/Workspace/ConfigUser/Main.vue')
      },
    ]
  },
  {
    path: '/*',
    component: () => import('@/views/NotFound/Main.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
