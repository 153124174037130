import interceptor from '@/plugins/interceptor';
const pathController = '/Schedule'

export default {
  state: () => ({
    listSchedules: []
  }),
  getters: {
    schedule_getSchedule: state => tabId => {
      let item = state.listSchedules[tabId];
      if (!item) {
        item = {
          tabId: tabId,
          commercialBreaks: [],
          shows: []
        }
        state.listSchedules[tabId] = item;
      }
      return item;
    }
  },
  mutations: {
    schedule_setSchedule(state, model) {
      state.listSchedules[model.tabId] = model;
    },
    schedule_addCommercialBreaks(state, model) {
      if ('index' in model) {
        state.listSchedules[model.tabId].commercialBreaks[model.index] = model;
      } else {
        state.listSchedules[model.tabId].commercialBreaks.push(model);
      }
    },
    schedule_addShows(state, model) {
      if ('index' in model) {
        state.listSchedules[model.tabId].shows[model.index] = model;
      } else {
        state.listSchedules[model.tabId].shows.push(model);
      }
    },
    schedule_removeCommercialBreaks(state, model) {
      const idx = state.listSchedules[model.tabId].commercialBreaks.findIndex(commercial => commercial.time === model.time);
      state.listSchedules[model.tabId].commercialBreaks.splice(idx, 1);
    },
    schedule_removeShows(state, model) {
      const idx = state.listSchedules[model.tabId].shows.findIndex(show => show.timeStart === model.timeStart && show.timeEnd === model.timeEnd);
      state.listSchedules[model.tabId].shows.splice(idx, 1);
    }
  },
  actions: {
    async shedule_getSchedule({commit, dispatch}, params) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getSchedule?stationId=${params.stationId}&dayOfWeek=${params.dayOfWeek}`);
        commit('schedule_setSchedule', {...request.data, tabId: params.tabId});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.station_config.schedules'), error: true});
        throw new Error(error);
      }
    },
    async schedule_update({dispatch}, model) {
      try {
        await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update?stationId=${model.params.stationId}&dayOfWeek=${model.params.dayOfWeek}`, model.model)
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.station_config.schedules')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.station_config.schedules'), error: true});
        throw new Error(error);
      }
    },
    async schedule_copyDay({dispatch}, model) {
      try {
        await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/copyDay`, model)
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.copyDay'), title: window.vueApp.$t('left_menu.station_config.schedules')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.station_config.schedules'), error: true});
        throw new Error(error);
      }
    },
    async schedule_copyStation({dispatch}, model) {
      try {
        await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/copyStation`, model)
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.copyStation'), title: window.vueApp.$t('left_menu.station_config.schedules')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.station_config.schedules'), error: true});
        throw new Error(error);
      }
    },
    schedule_addCommercialBreaks({commit}, model) {
      commit("schedule_addCommercialBreaks", model)
    },
    schedule_addShows({commit}, model) {
      commit("schedule_addShows", model);
    },
    schedule_removeCommercialBreaks({commit}, model) {
      commit("schedule_removeCommercialBreaks", model)
    },
    schedule_removeShows({commit}, model) {
      commit("schedule_removeShows", model);
    }
  }
}