<template>
  <div class="notification" :class="{'error': notification.error}">
    <div class="notification__text">
      <strong>{{notification.title.toUpperCase()}}</strong>
      <span v-html="notification.text"></span>
    </div>
    <a class="notification__close" @click="removeNotification">
      <i class="material-icons">icon_close</i>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    notification: Object
  },
  methods: {
    removeNotification() {
      this.$store.dispatch('notifications_delete', this.notification.guid);
    }
  },
  created() {
    if (!this.notification.withAction) {
      setTimeout(() => {
        this.removeNotification();
      }, 6000);
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.notification {
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 400px;
  background: $primary-color;
  border-radius: 4px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  padding: 16px;
  padding-right: 48px;
  margin: 8px 0;
  position: relative;
  color: $dark-gray-color;

  &.action {
    padding-right: 16px;
  }

  &.error {
    background: #f44336;

    .notification__text, .notification__close {
      color: white;
    }
  }

  &__text {
    @include font-settings(milli, base);
    color: $dark-gray-color;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      margin-right: 8px;
    }

    &__options {
      display: inline-flex;
      justify-content: space-around;

      a {
        font-weight: 700;
        margin-right: 16px;
        text-transform: uppercase;

        &.accept {
          color: $secondary-color;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: 16px;
    top: 12px;
    color: $dark-gray-color;
  }
}
</style>
