import { createI18n } from 'vue-i18n/index';
import PersistentData from '@/plugins/persistentData'

// Detect language navigator
let languageNavigator = navigator.language.split('-')[0].toUpperCase();

// Validate navigator
if (languageNavigator !== 'EN' && languageNavigator !== 'ES') {
  languageNavigator = 'EN';
}

// LocalStorage
const langPersistent = PersistentData.get('lang');
if (langPersistent !== null) {
  languageNavigator = langPersistent;
} else {
  PersistentData.set('lang', languageNavigator);
}

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = createI18n({
  legacy: false,
  locale: languageNavigator,
  fallbackLocale: 'EN',
  messages: loadLocaleMessages(),
  globalInjection: true
});

window.i18n = i18n;
export default i18n;