import interceptor from '@/plugins/interceptor';
const pathController = '/Spot'

export default {
  state: () => ({
    spots: []
  }),
  getters: {
    spots_spots: state => filter => state.spots.filter(spot => {
      // Obtener los que tienen valor
      const filtersActives = {
        stationName: (filter.stationName != ''),
        adAgencyName: (filter.adAgencyName != ''),
        customerName: (filter.customerName != ''),
        spotName: (filter.spotName != ''),
        internalNumber: (filter.internalNumber != ''),
        agencyOrderNumber: (filter.agencyOrderNumber != '')
      };
      // Consultar solo por los activos
      let valid = true;
      Object.keys(filtersActives).forEach(active => {
        if (filtersActives[active] && valid) {
          valid = `${spot[active]}`.toLowerCase().includes(`${filter[active]}`.toLowerCase());
        }
      });

      return valid;
    }),
  },
  mutations: { 
    spots_setSpots(state, value) {
      state.spots = value;
    },
    spots_updateSpots(state, model) {
      const idx = state.spots.findIndex(p => p.id == model.id);
      state.spots[idx] = model;
    },
    spots_deleteSpots(state, id) {
      const idx = state.spots.findIndex(p => p.id == id);
      state.spots.splice(idx, 1);
    }
  },
  actions: {
    async spots_getAll({ commit, dispatch }) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getAll`);
        commit('spots_setSpots', request.data);
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.spot_programming.spot'), error: true});
        throw new Error(error);
      }
    },
    async spots_getById({ dispatch }, param) {
      try {
        const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/getById?id=${param.id}`);
        return request.data;
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.message}`, title: window.vueApp.$t('left_menu.spot_programming.spot'), error: true});
        throw new Error(error);
      }
    },
    async spots_create({ dispatch }, model) {
      try {
        const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/create`, model);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.create'), title: window.vueApp.$t('left_menu.spot_programming.spot')});
        return request.data;
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.spot_programming.spot'), error: true});
        throw new Error(error);
      }
    },
    async spots_update({ dispatch }, model) {
      try {
        await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/update`, model);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.update'), title: window.vueApp.$t('left_menu.spot_programming.spot')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.spot_programming.spot'), error: true});
        throw new Error(error);
      }
    }, 
    async spots_delete({ commit, dispatch }, model) {
      try {
        await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/delete?spotId=${model.id}`);
        commit('spots_deleteSpots', model.id);
        dispatch('notifications_create', {text: window.vueApp.$t('notifications.delete'), title: window.vueApp.$t('left_menu.spot_programming.spot')});
      } catch (error) {
        dispatch('notifications_create', {text: `${window.vueApp.$t('notifications.general')} ${error.response.data ? window.vueApp.$t(`notifications.validation.${error.response.data}`) : error.message}`, title: window.vueApp.$t('left_menu.spot_programming.spot'), error: true});
        throw new Error(error);
      }
    },
  }
}

