<template>
  <div class="component-modal" id="modal">
    <form class="form" v-on:submit.prevent>
      <legend>{{props ? `${$t("modal.station_config.schedules.edit")}` : $t("modal.station_config.schedules.create")}}</legend>
      <i class="material-icons icon" @click.stop.prevent="closeModal">close</i>
    
      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <input type="time" v-model="model.time" @change="updateDuration" :class="{'invalid': submitted && (v$.model.time.$invalid || commercialExist)}">
          <label for="time">{{$t("modal.station_config.schedules.hour")}} <small>*</small></label>
        </div>

        <!-- Alert validation -->
        <div class="alert warning" v-if="commercialExist">
          <i class="material-icons">info_outline</i>
          {{$t("modal.station_config.schedules.commercialSameHour")}}
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <select name="" id="" v-model="model.timeType" @change="updateDuration">
            <option :value="0">{{ $t("modal.station_config.schedules.hour_init") }}</option>
            <option :value="1">{{ $t("modal.station_config.schedules.hour_end") }}</option>
          </select>
          <label for="code">{{$t("modal.station_config.schedules.ref_hour")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <select name="" id="" v-model="model.toleranceInMinutes">
            <option :value="3">{{ $t("modal.station_config.schedules.SOFT") }}</option>
            <option :value="0">{{ $t("modal.station_config.schedules.HARD") }}</option>
          </select>
          <label for="code">{{$t("modal.station_config.schedules.start_time_logic")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-input component-modal-input--rename">
          <select name="" id="" v-model="model.spotType">
            <option :value="0" selected>{{ $t("modal.station_config.schedules.type_spot") }}</option>
            <option :value="2">{{ $t("modal.station_config.schedules.type_sponsor") }}</option>
          </select>
          <label for="code">{{$t("modal.station_config.schedules.type")}} <small>*</small></label>
        </div>
      </div>

      <div class="component-modal__row">
        <div class="component-modal-checkbox">
          <label for="allow">
            <input type="checkbox" id="allow" v-model="model.allowMixSpotCategory">
            {{$t("modal.station_config.schedules.mix")}}  
          </label>
        </div>
      </div>
    
      <button type="button" @click.stop.prevent="saveAndClose">
        {{ props ? $t("modal.save") : $t('modal.create') }}
      </button>
      <button type="button" class="button-cancel" @click.stop.prevent="closeModal">
        {{ $t("modal.cancel") }}
      </button>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { required } from '@vuelidate/validators';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { useRoute } from 'vue-router';

export default {
  setup() {
		const v$ = useVuelidate();
    const store = useStore();
    const route = useRoute();
    const props = computed(() => store.getters.modal_getProps);
    const schedule = computed(() => store.getters.schedule_getSchedule(route.params.id));
    const loadForm = ref(false);
    const commercialExist = ref(null);
    const submitted = ref(false);
    const model = ref({
      time: '00:00',
      timeType: 0,
      toleranceInMinutes: 3,
      allowMixSpotCategory: false,
      spotType: 0
    });

    onMounted(() => {
      if (props.value) {
        model.value = Object.assign({}, props.value);
        model.value.allowMixSpotCategory = model.value.allowMixSpotCategory == 1 ? true : false;  
      }
      loadForm.value = true;
    })

    async function closeModal() {
      await store.dispatch('modal_setActive');
      submitted.value = false;
    }

    async function saveAndClose() {
      submitted.value = true;
      updateDuration();
      if (!v$.value.model.$invalid && !commercialExist.value) {
        model.value.allowMixSpotCategory = model.value.allowMixSpotCategory ? 1 : 0;
        try {
          await store.dispatch('schedule_addCommercialBreaks', {...model.value, tabId: route.params.id});
          await store.dispatch('tabs_setPropChanges', {id: route.params.id, changes: true});
          this.closeModal();
        } catch (error) {
          console.log(error);
        }
      }
    }

    function updateDuration() {
      // Verificar que no exista el horario en el mismo type
      commercialExist.value = schedule.value.commercialBreaks.find(commercial => {
        if(props.value && commercial.id == props.value.id) return;
        return commercial.time == model.value.time && commercial.timeType == model.value.timeType;
      });
    }

    return {
      v$,
      props,
      model,
      commercialExist,
      loadForm,
      submitted,
      saveAndClose,
      closeModal,
      updateDuration,
    }
  },
	validations () {
    return {
      model: {
				time: {required}
			}
    }
  }
};
</script>