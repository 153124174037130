import interceptor from '@/plugins/interceptor';
const pathController = '/Common'

export default {
  actions: {
    async common_version() {
        return await interceptor.anonymous.get(`${process.env.VUE_APP_API_URL}${pathController}/version`);
    }
  }
}

